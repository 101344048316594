import { MenuItem } from "@chakra-ui/menu";
import { Button } from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";

const StyledMenuItem = ({ setSelectedItem, item, children, closeSubMenu }) => {
  const refMenuItem = useRef({ current: null });

  useEffect(() => {
    var currentMenuItem = refMenuItem.current;
  
    if (currentMenuItem && closeSubMenu) {
      currentMenuItem.addEventListener("mouseenter", closeSubMenu);
    }

    return () => {
      if (currentMenuItem && closeSubMenu) {
        currentMenuItem.removeEventListener("mouseenter", closeSubMenu);
      }
    };
  }, [closeSubMenu]);

  const handleSelectItem = (value) => {
    setSelectedItem(value);
  };

  return (
    <MenuItem
      as={Button}
      onClick= {() => handleSelectItem(item)}
      px={4}
      mb={0}
      w="full"
      ref={refMenuItem}
      boxShadow="0px 2px 0px 0px transparent"
      // boxShadow="none"
      borderRadius={0}
      border="none"
      justifyContent="flex-start"
      bg="transparent"
      _hover={{
        bg: "blue.50"
      }}
      _focus={{
        boxShadow: "0px 2px 0px 0px #47bae6"
      }}
    >
      {children}
    </MenuItem>
  );
};

export default StyledMenuItem;

import React, { useContext } from "react";
import { ModalContext } from "../contexts/ModalContext";

const Modal = () => {
  function handleClose(e) {
    if (e.target.id === "modal-container") closeModal();
  }
  const { modalContent, closeModal } = useContext(ModalContext);
  if (!modalContent) return null;
  return (
    <div
      id="modal-container"
      className="fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-60 backdrop-blur-sm"
      onClick={handleClose}
    >
      {modalContent}
    </div>
  );
};

export default Modal;

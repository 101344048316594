import React from "react";
import ModalContextProvider from "../contexts/ModalContext";

import Footer from "./Footer/footer";
import Hero from "./Hero";
import Modal from "./Modal";
import Navbar from "./NavigationBar/Navbar";

const HomePage = () => {
  return (
    <ModalContextProvider>
      <Navbar />
      <Hero />
      <Footer isLoggedIn={false} />
      <Modal />
    </ModalContextProvider>
  );
};

export default HomePage;

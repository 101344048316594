import React, { useContext, useState } from "react";
import { ModalContext } from "../contexts/ModalContext";
import { useAuth } from "../contexts/AuthContext";
import SignupModal from "./Modal/SignupModal";
import { useNavigate } from "react-router-dom";
import ResetPasswordModal from "./Modal/ResetPasswordModal";

const LoginModal = () => {
  const [error, setError] = useState("");
  const [loginForm, setLoginForm] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);

  const { login } = useAuth();
  const { openModal } = useContext(ModalContext);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setError(false);
    setLoading(true);

    if (loginForm.email.length <= 0) {
      setLoading(false);
    }
    if (loginForm.password.length <= 0) {
      setLoading(false);
    }

    await login(loginForm.email, loginForm.password)
      .then(() => {
        navigate("/chatdashboard");
        // closeModal();
      })
      .catch((err) => {
        setLoading(false);
        setError("Incorrect email or password.");
        console.log(err);
      });
  };

  return (
    <div className="fixed justify-center">
      <div className="rounded-xl bg-white p-8 md:min-w-[500px]">
        <h1 className="my-5 text-center text-4xl font-bold md:text-5xl">
          Welcome Back
        </h1>
        <form
          className="w-full rounded-xl p-8 md:w-full"
          onSubmit={handleSubmit}
        >
          <label
            htmlFor="email"
            className="mb-2 block font-medium text-gray-600"
          >
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={loginForm.email}
            onChange={(e) =>
              setLoginForm({ ...loginForm, email: e.target.value })
            }
            className="mb-4 w-full rounded-lg border border-gray-300 px-4 py-2"
            required
          />
          <label
            htmlFor="password"
            className="mb-2 block font-medium text-gray-600"
          >
            Password
          </label>
          <input
            type="password"
            id="password"
            name="password"
            value={loginForm.password}
            onChange={(e) =>
              setLoginForm({ ...loginForm, password: e.target.value })
            }
            className="mb-4 w-full rounded-lg border border-gray-300 px-4 py-2"
            required
          />
          {!loading && <p className="mb-1 max-w-sm text-red-600">{error}</p>}
          <p className="mb-3">
            <span
              onClick={() => openModal(<ResetPasswordModal />)}
              className="cursor-pointer font-medium text-indigo-600"
            >
              Forgot Password?
            </span>
          </p>
          <button
            className="w-full rounded-lg bg-indigo-600 px-4 py-2 text-white"
            disabled={loading}
          >
            {loading ? "Loading..." : "Log In"}
          </button>
          <p className="mt-4 text-center">
            Don&apos;t have an account?{" "}
            <span
              onClick={() => openModal(<SignupModal />)}
              className="cursor-pointer font-medium text-indigo-600"
            >
              Sign Up
            </span>
          </p>
        </form>
      </div>
    </div>
  );
};

export default LoginModal;

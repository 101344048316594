import React, { useEffect, useState } from "react";
import ModalContextProvider from "../contexts/ModalContext";
import FastAPIClient from "../client";
import { useNavigate } from "react-router-dom";
import config from "../config";
import Footer from "./Footer/footer";
import Modal from "./Modal";
import Navbar from "./NavigationBar/Navbar";

const client = new FastAPIClient(config);

const UpdatePassword = () => {
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const email = urlParams.get("email");
    const token = urlParams.get("token");
    setEmail(email);
    setToken(token);

    client.login_from_reset(token);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (password !== password1) {
      setError("Password does not match");
      setLoading(false);
      return;
    }

    await client
      .updatePassword(email, password, token)
      .then(() => {
        alert("Successfully changed password!");
        navigate("/");
      })
      .catch((err) => {
        setError("There was an error verifing your email. Please try again.");
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <ModalContextProvider>
      <Navbar />
      <div
        name="home"
        className="flex h-full w-full flex-col justify-between bg-zinc-200 py-16"
      >
        <div className="m-auto max-w-[1240px]">
          <div className="mt-8">
            <div className="rounded-xl bg-white p-8 md:min-w-[500px]">
              <h1 className="my-5 text-center text-4xl font-bold md:text-5xl">
                Update Password
              </h1>
              <form
                className="w-full rounded-xl p-8 md:w-full"
                onSubmit={handleSubmit}
              >
                <label
                  htmlFor="email"
                  className="mb-2 block font-medium text-gray-600"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  className="mb-4 w-full rounded-lg border border-gray-300 px-4 py-2"
                  required
                  disabled
                />
                <label
                  htmlFor="password"
                  className="mb-2 block font-medium text-gray-600"
                >
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="mb-4 w-full rounded-lg border border-gray-300 px-4 py-2"
                  required
                />
                <label
                  htmlFor="password2"
                  className="mb-2 block font-medium text-gray-600"
                >
                  Re-Enter Password
                </label>
                <input
                  type="password"
                  id="password2"
                  name="password2"
                  value={password1}
                  onChange={(e) => setPassword1(e.target.value)}
                  className="mb-4 w-full rounded-lg border border-gray-300 px-4 py-2"
                  required
                />
                {!loading && (
                  <p className="mb-1 max-w-sm text-red-600">{error}</p>
                )}
                <button
                  className="w-full rounded-lg bg-indigo-600 px-4 py-2 text-white hover:bg-transparent"
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Submit"}
                </button>
                <p className="mt-4 text-center">
                  Don&apos;t have an account?{" "}
                  <span className="cursor-pointer font-medium text-indigo-600">
                    Sign Up
                  </span>
                </p>
              </form>
            </div>
          </div>
          <div />
        </div>
      </div>
      <Footer />
      <Modal />
    </ModalContextProvider>
  );
};

export default UpdatePassword;

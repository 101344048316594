import React, { useContext } from "react";

import bgImg from "../assets/images/cyber-bg.png";
import { ModalContext } from "../contexts/ModalContext";
import Signup from "./Modal/SignupModal";

import { Button } from "@chakra-ui/react";

const Hero = () => {
  const { openModal } = useContext(ModalContext);
  // const [email, setEmail] = useState('');
  // const [isValidEmail, setIsValidEmail] = useState(true); // Track email validation status

  // const handleEmailChange = (e) => {
  //   setEmail(e.target.value);

  //   const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  //   setIsValidEmail(emailPattern.test(email));
  // };

  // const handleSubscribe = () => {
  //   if (isValidEmail) {
  //     console.log(`Subscribed with email: ${email}`);
  //   } else {
  //     alert('Please enter a valid email address.');
  //   }
  // };

  return (
    <div
      name="home"
      className="flex h-screen w-full flex-col justify-between bg-zinc-200 py-16"
    >
      <div className="m-auto grid max-w-[1240px] md:grid-cols-2">
        <div className="flex w-full flex-col justify-center px-2 py-8 md:items-start">
          <h1 className="py-3 text-5xl font-bold md:text-6xl">
            Helping Marketers Succeed
          </h1>
          <p className="my-2 text-xl">
            We are training an AI Agent for Marketers called{" "}
            <span className="font-bold">Ahoora</span>. The dialogue 
            is helping to solve complex marketing problems. 
            <span className="font-bold"> Ahoora</span> is currently 
            focused on answering Google Ads questions for Paid Search Marketers.
          </p>
          {/* <Flex justifyContent="center" alignItems="center" mt={6}>
            <Input
              type="email"
              placeholder="Enter your email address"
              value={email}
              onChange={handleEmailChange}
              maxW="sm"
              size="md"
              mr={2}
              bg="white"
            />
            <Button size="md" px={10} onClick={handleSubscribe}>Get Notified</Button>
          </Flex>        */}
          {/* <button className='w-full md:w-1/2 py-3 px-6 my-4' onClick={() => openModal(<Signup />)}>Ask Ahoora</button> */}
          <Button onClick={() => openModal(<Signup />)}> Ask Ahoora </Button>
        </div>
        <div>
          <img className="w-full" src={bgImg} alt="/" />
        </div>
      </div>
    </div>
  );
};

export default Hero;

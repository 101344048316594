import React, { useState } from "react";

import { differenceInCalendarDays, format, isValid, isBefore, isAfter, parse } from 'date-fns';
import { DayPicker, Row } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

function isFutureDate(date) {
    return differenceInCalendarDays(date, new Date()) > 0;
  }
  
  function OnlyPastRow(props) {
    const isFutureRow = props.dates.every(isFutureDate);
    if (isFutureRow) return <></>;
    return <Row {...props} />;
  }

// const defaultSelected = {
//     from: subDays(new Date(), 7),
//     to: new Date()
// };

// export const currentSelected = {
//     from: subDays(new Date(), 7),
//     to: new Date()
// };

export default function DateRangePicker({fromValue, setFromValue, toValue, setToValue}) {
    // const [fromValue, setFromValue] = useState(format(defaultSelected.from, 'dd-MM-yyyy'));
    // const [toValue, setToValue] = useState(format(defaultSelected.to, 'dd-MM-yyyy'));
    const [range, setRange] = useState({
        from: parse(fromValue, 'dd-MM-yyyy', new Date()),
        to: parse(toValue, 'dd-MM-yyyy', new Date())
    })
    // useState(defaultSelected);

    // useEffect(() => {
    //     currentSelected.from = fromValue;
    //     currentSelected.to = toValue;
    //   }, [fromValue, toValue]);

    const handleFromChange = (e) => {
        setFromValue(e.target.value);
        const date = parse(e.target.value, 'dd-MM-yyyy', new Date());
        if (!isValid(date)) {
          return setRange({ from: undefined, to: undefined });
        }
        if (range?.to && isAfter(date, range.to)) {
          setRange({ from: range.to, to: date });
        } else {
          setRange({ from: date, to: range?.to });
        }
      };
    
      const handleToChange = (e) => {
        setToValue(e.target.value);
        const date = parse(e.target.value, 'dd-MM-yyyy', new Date());
    
        if (!isValid(date)) {
          return setRange({ from: range?.from, to: undefined });
        }
        if (range?.from && isBefore(date, range.from)) {
          setRange({ from: date, to: range.from });
        } else {
          setRange({ from: range?.from, to: date });
        }
      };
    
      const handleRangeSelect = (dateRange) => {
        setRange(dateRange);
        if (dateRange?.from) {
          setFromValue(format(dateRange.from, 'dd-MM-yyyy'));
        } else {
          setFromValue(null);
        }
        if (dateRange?.to) {
          setToValue(format(dateRange.to, 'dd-MM-yyyy'));
        } else {
          setToValue(null);
        }
      };

    return  (
        <DayPicker
            mode="range"
            components={{ OnlyPastRow }}
            hidden={isFutureDate}
            selected={range}
            onSelect={handleRangeSelect}
            // numberOfMonths={2}
            showOutsideDays
            footer={
                <form className="ma2">
                <input
                    size={7}
                    placeholder="From Date"
                    value={fromValue}
                    onChange={handleFromChange}
                />
                {' -  '}
                <input
                    size={7}
                    placeholder="To Date"
                    value={toValue}
                    onChange={handleToChange}
                />
                </form>
            }
            />
    );
}
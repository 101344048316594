import React, { useContext, useState } from "react";
import { ModalContext } from "../../contexts/ModalContext";
import LoginModal from "../LoginModal";
import FastAPIClient from "../../client";
import config from "../../config";

const client = new FastAPIClient(config);

const SignupModal = () => {
  const [registerForm, setRegisterForm] = useState({
    email: "",
    password: "",
    first_name: "",
    last_name: "",
    title: "",
    organization: "",
    is_marketer: false,
    is_consent: true,
    marketer_questions: {},
  });
  // const [error, setError] = useState({ email: '', password: '', first_name: '', last_name: '' });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { openModal } = useContext(ModalContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    // setError(false);

    if (registerForm.first_name.length <= 0) {
      setLoading(false);
      // return setError({fullName: "Please Enter Your Full Name"})
    }
    if (registerForm.last_name.length <= 0) {
      setLoading(false);
      // return setError({fullName: "Please Enter Your Full Name"})
    }
    if (registerForm.email.length <= 0) {
      setLoading(false);
      // return setError({email: "Please Enter Email Address"})
    }
    if (registerForm.password.length <= 0) {
      setLoading(false);
      // return setError({password: "Please Enter Password"})
    }

    await client
      .register(
        registerForm.email,
        registerForm.password,
        registerForm.first_name,
        registerForm.last_name,
        registerForm.title,
        registerForm.organization,
        registerForm.is_marketer,
        registerForm.is_consent,
        registerForm.marketer_questions
      )
      .then((data) => {
        if (data[0]) {
          openModal(<LoginModal />);
        } else {
          setLoading(false);
          setError(data[1]);
        }
      })
      .catch(() => {
        setLoading(false);
        setError("Something went wrong. Please try again!");
      });

    // closeModal();
  };

  return (
    <div className="fixed justify-center">
      <div className="h-screen overflow-y-auto rounded-xl bg-white p-5 md:min-w-[500px]">
        <h1 className="my-3 text-center text-4xl font-bold md:text-5xl">
          Sign Up
        </h1>
        <form
          className="w-full rounded-xl p-8 md:w-full"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-row justify-between">
            <div className="flex flex-col">
              <label
                htmlFor="fname"
                className="mb-2 block font-medium text-gray-600"
              >
                First Name
              </label>
              <input
                type="text"
                id="fname"
                onChange={(e) =>
                  setRegisterForm({
                    ...registerForm,
                    first_name: e.target.value,
                  })
                }
                value={registerForm.first_name}
                name="fname"
                className="mb-4 w-full rounded-lg border border-gray-300 px-4 py-2"
                required
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="lname"
                className="mb-2 block font-medium text-gray-600"
              >
                Last Name
              </label>
              <input
                type="text"
                id="lname"
                name="lname"
                onChange={(e) =>
                  setRegisterForm({
                    ...registerForm,
                    last_name: e.target.value,
                  })
                }
                value={registerForm.last_name}
                className="mb-4 w-full rounded-lg border border-gray-300 px-4 py-2"
                required
              />
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <div className="flex flex-col">
              <label
                htmlFor="title"
                className="mb-2 block font-medium text-gray-600"
              >
                Title
              </label>
              <input
                type="text"
                id="title"
                onChange={(e) =>
                  setRegisterForm({ ...registerForm, title: e.target.value })
                }
                value={registerForm.title}
                name="title"
                className="mb-4 w-full rounded-lg border border-gray-300 px-4 py-2"
                required
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="organization"
                className="mb-2 block font-medium text-gray-600"
              >
                Organization
              </label>
              <input
                type="text"
                id="organization"
                name="organization"
                onChange={(e) =>
                  setRegisterForm({
                    ...registerForm,
                    organization: e.target.value,
                  })
                }
                value={registerForm.organization}
                className="mb-4 w-full rounded-lg border border-gray-300 px-4 py-2"
                required
              />
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <div className="flex flex-col">
              <label
                htmlFor="email"
                className="mb-2 block font-medium text-gray-600"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                onChange={(e) =>
                  setRegisterForm({ ...registerForm, email: e.target.value })
                }
                value={registerForm.email}
                className="mb-4 w-full rounded-lg border border-gray-300 px-4 py-2"
                required
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="password"
                className="mb-2 block font-medium text-gray-600"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                name="password"
                onChange={(e) =>
                  setRegisterForm({ ...registerForm, password: e.target.value })
                }
                value={registerForm.password}
                className="mb-4 w-full rounded-lg border border-gray-300 px-4 py-2"
                required
              />
            </div>
          </div>
          <div className="max-w-[450px]">
            <label
              htmlFor="question1"
              className="mb-2 block font-medium text-gray-600"
            >
              What are your top three marketing challenges or questions?
            </label>
            <input
              type="text"
              id="question1"
              name="question1"
              onChange={(e) =>
                setRegisterForm({
                  ...registerForm,
                  marketer_questions: {
                    ...registerForm.marketer_questions,
                    0: e.target.value,
                  },
                })
              }
              placeholder="Enter your first question"
              className="mb-4 w-full rounded-lg border border-gray-300 px-4 py-2"
              required
            />
            <input
              type="text"
              id="question2"
              name="question2"
              onChange={(e) =>
                setRegisterForm({
                  ...registerForm,
                  marketer_questions: {
                    ...registerForm.marketer_questions,
                    1: e.target.value,
                  },
                })
              }
              placeholder="Enter your second question (optional)"
              className="mb-4 w-full rounded-lg border border-gray-300 px-4 py-2"
            />
            <input
              type="text"
              id="question3"
              name="question3"
              onChange={(e) =>
                setRegisterForm({
                  ...registerForm,
                  marketer_questions: {
                    ...registerForm.marketer_questions,
                    2: e.target.value,
                  },
                })
              }
              placeholder="Enter your third question (optional)"
              className="mb-4 w-full rounded-lg border border-gray-300 px-4 py-2"
            />
          </div>
          <label className="mb-2 block font-medium text-gray-600">
            Are you a marketer?
          </label>
          <input
            type="radio"
            onChange={() =>
              setRegisterForm({ ...registerForm, is_marketer: true })
            }
            checked={registerForm.is_marketer}
          />{" "}
          Yes
          <input
            className="mb-3 ml-2"
            type="radio"
            onChange={() =>
              setRegisterForm({ ...registerForm, is_marketer: false })
            }
            checked={!registerForm.is_marketer}
          />{" "}
          No
          <label className="mb-2 block font-medium text-gray-600">
            I consent to receive future updates on the progress of Ahoora&apos;s
            training.
          </label>
          <input
            type="radio"
            onChange={() =>
              setRegisterForm({ ...registerForm, is_consent: true })
            }
            checked={registerForm.is_consent}
          />{" "}
          Yes
          <input
            className="mb-3 ml-2"
            type="radio"
            onChange={() =>
              setRegisterForm({ ...registerForm, is_consent: false })
            }
            checked={!registerForm.is_consent}
          />{" "}
          No
          {!loading && <p className="my-2 text-red-600">{error}</p>}
          <button
            className="w-full rounded-lg bg-indigo-600 px-4 py-2 text-white hover:bg-transparent"
            disabled={loading}
          >
            {loading ? "Loading..." : "Sign Up"}
          </button>
          <p className="mt-4 text-center">
            Already have an account?{" "}
            <span
              className="cursor-pointer font-medium text-indigo-600"
              onClick={() => openModal(<LoginModal />)}
            >
              Log In
            </span>
          </p>
        </form>
      </div>
    </div>
  );
};

export default SignupModal;

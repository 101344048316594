import React, { useState, useEffect } from 'react';

import FastAPIClient from "../../client";
import config from "../../config";

import {
  Heading, Text, VStack, HStack,  
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';

const client = new FastAPIClient(config);

const PricingPageModal = ({ isOpen, onClose }) => {

  const handleMakePayment = async () => {
    await client.makeStripePayment();
  };

  const handleOpenSubscriptionDetails = async () => {
    const billing_portal = true;
    await client.handleStripeBillingCheckoutSession(billing_portal);
  };

  const [stripeSession, setStripeSession] = useState(null);

  useEffect(() => {
    const fetchSession = async () => {
      try {
        const session = await client.fetchStripeSession();
        setStripeSession(session);
      } catch (error) {
        console.error('Error fetching Stripe session:', error);
      }
    };

    fetchSession();
  }, []);


  return (
    <>
      {/* Modal Popup */}
      <Modal isOpen={isOpen} onClose={onClose} size={'full'} isCentered>
        <ModalOverlay />
        <ModalContent
          // bg="#132e62"
          position="relative" // Enable positioning of the close button
        >
          <ModalCloseButton
            sx={{
              color: "white", // Change text/icon color
              bg: "black", // Change background color
              border: "none",
            }} />
          <ModalBody
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            {stripeSession ? (
              <>
                <Box
                  width="100vw"
                  px="1rem"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  // bg="gray.50"
                >
                  <Heading m={10} textAlign="center">
                    Upgrade your plan
                  </Heading>

                  <Box
                    border="1px solid"
                    borderColor="gray.300"
                    borderRadius="md"
                    p={4}
                    mt={10}
                    width="20%"
                    height="60vh"  // Set a height for the box
                    display="flex"
                  >
                    {/* <Divider orientation="vertical" borderColor="gray.300" /> */}

                    <Box flex="1" p={4} display="flex" flexDirection="column" justifyContent="space-between">
                      <VStack spacing={2} align="start">
                        <Text fontSize="2xl" fontWeight="bold">
                          Plus Beta
                        </Text>
                        <Text fontSize="lg" color="gray.600">
                          USD $100/month
                        </Text>
                        {/* send to billing portal */}
                        <Button width="80%" colorScheme="teal" onClick={handleMakePayment}>
                          Upgrade to Plus
                        </Button>
                        <br />
                        <HStack spacing={1}>
                          <CheckIcon color="green.500" />
                          <Text>Unlimited account integrations</Text>
                        </HStack>
                        <HStack spacing={1}>
                          <CheckIcon color="green.500" />
                          <Text>Unlimited conversations</Text>
                        </HStack>
                        <HStack spacing={1}>
                          <CheckIcon color="green.500" />
                          <Text>Unlimited usage</Text>
                        </HStack>
                        <HStack spacing={1}>
                          <CheckIcon color="green.500" />
                          <Text>Early access to new features</Text>
                        </HStack>
                      </VStack>

                      <VStack align="start" spacing={1} mt={2}>
                        <Button variant="link" border={'none'} onClick={handleOpenSubscriptionDetails}>
                          Invoices
                        </Button>
                      </VStack>
                    </Box>
                  </Box>

                  {/* Content underneath the box */}
                  <VStack spacing={4} mt={8}>
                    <Text></Text>
                  </VStack>
                </Box>
              </>
            ) : (
              <>
                <div>Loading pricing options...</div>
              </>
            )}
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  );
};

export default PricingPageModal;
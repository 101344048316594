import React from "react";

function JSONTable({ data, text }) {
  if (!Array.isArray(data) || data.length === 0) {
    return null;
  }

  // Extract column names from the first row's keys
  const columns = Object.keys(data[0]);

  return (
    <div className="border-collapse">
      <p className="mb-2"> <br></br>{text} </p>
      <table>
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th className="border border-slate-300 p-2" key={index}>
                {column}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {columns.map((column, colIndex) => (
                <td className="border border-slate-300 p-2" key={colIndex}>
                  {row[column]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default JSONTable;

import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Text,
  Button,
} from '@chakra-ui/react';

import FastAPIClient from "../../client";
import config from "../../config";

const client = new FastAPIClient(config);

const handleMakePayment = async () => {
  await client.makeStripePayment();
};

const DashboardAlertModal = ({ reason, isOpen, onClose }) => {

    var header = ""
    var message = "Ok"
    var linkText = ""
    // var linkUrl = null 

    if (reason == 'unsubscribed') {
        message = "It looks like you're trying to ask a question. Please subscribe to unlock full access and get answers to your questions!"
        linkText = "Click here to subscribe"
        // linkUrl = handleMakePayment
    } else if (reason == 'success') {
        message = "Subscription confirmed"
    } else if (reason == 'testuser') {
        message = "Test user status"
    }

  return (
    <>
      {/* Modal Popup */}
      <Modal isOpen={isOpen} onClose={onClose} size={'sm'} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> {header} </ModalHeader>
          <ModalCloseButton           
            sx={{
                color: "black", // Change text/icon color
                bg: "white", // Change background color
                border: "none",
          }}/>
          <ModalBody>
            <Text fontSize="sm" textAlign="center">
                {message}{' '}
                <br />
                {reason === 'unsubscribed' && (
                    <Button 
                        as="a" 
                        onClick={handleMakePayment} 
                        // color="teal.500" 
                        variant="link" 
                        fontWeight="normal" 
                        textDecoration="underline"
                    >
                        {linkText}
                    </Button>
                )}
                </Text>
          </ModalBody>

          <ModalFooter>
            {/* <Button colorScheme="blue" mr={3} onClick={onClose}>
              Got it!
            </Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DashboardAlertModal;

import React, { useContext, useState } from "react";
import { ModalContext } from "../../contexts/ModalContext";
import FastAPIClient from "../../client";
import config from "../../config";
import { useNavigate } from "react-router-dom";
import LoginModal from "../LoginModal";

const client = new FastAPIClient(config);

const ResetPassword = () => {
  const [error, setError] = useState("");
  const [resetForm, setResetForm] = useState({ email: "" });
  const [loading, setLoading] = useState(false);

  const { openModal, closeModal } = useContext(ModalContext);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // setError(false);
    setLoading(true);

    if (resetForm.email.length <= 0) {
      setLoading(false);
      return false;
    }

    await client
      .resetPassword(resetForm.email)
      .then(() => {
        alert("An email has been sent to the provided email address.");
        navigate("/");
        closeModal();
      })
      .catch((err) => {
        setLoading(false);
        setError("There was an error verifing your email. Please try again.");
        console.error(err);
      });
  };

  return (
    <div className="fixed justify-center">
      <div className="rounded-xl bg-white p-8 md:min-w-[500px]">
        <h1 className="my-5 text-center text-4xl font-bold md:text-5xl">
          Reset Password
        </h1>
        <form
          className="w-full rounded-xl p-8 md:w-full"
          onSubmit={handleSubmit}
        >
          <label
            htmlFor="email"
            className="mb-2 block font-medium text-gray-600"
          >
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={resetForm.email}
            onChange={(e) =>
              setResetForm({ ...resetForm, email: e.target.value })
            }
            className="mb-4 w-full rounded-lg border border-gray-300 px-4 py-2"
            required
          />
          {!loading && <p className="mb-1 max-w-sm text-red-600">{error}</p>}
          <button
            className="w-full rounded-lg bg-indigo-600 px-4 py-2 text-white hover:bg-transparent"
            disabled={loading}
          >
            {loading ? "Loading..." : "Submit"}
          </button>
          <p className="mt-4 text-center">
            Already have an account?{" "}
            <span
              onClick={() => openModal(<LoginModal />)}
              className="cursor-pointer font-medium text-indigo-600"
            >
              Login
            </span>
          </p>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;

import {
    Button,
    Menu,
    MenuButton,
    MenuList,
    useDisclosure
  } from "@chakra-ui/react";
  import React, { useRef, useEffect, useCallback } from "react";
  import { ChevronDownIcon } from "@chakra-ui/icons";
  import MenuItemSubMenu from "./MenuItemSubMenu";
  import StyledMenuItem from "./MenuItem";

  function addEL(ref, event, handler) {
    if (ref) ref.addEventListener(event, handler);
  }
  function remEL(ref, event, handler) {
    if (ref) ref.removeEventListener(event, handler);
  }
  
  const InnerMenu = ({ setSelectedItem, title, childrenItems }) => {  
    const refSubMenuButton = useRef();
    const refSubMenuList = useRef();
  
    const { isOpen, onOpen, onClose } = useDisclosure();
  
    const subMenuButtonEnterHandle = useCallback(() => {
      onOpen();
      // fix bug with list animation
      refSubMenuList.current.style.pointerEvents = "auto";
    }, [onOpen]);
    const subMenuButtonLeaveHandle = useCallback(() => {
      onClose();
    }, [onClose]);
  
    // fix bug with list animation,
    // hide it when menu closed and open only if button menu is hovered
    useEffect(() => {
      if (!isOpen) {
        refSubMenuList.current.style.pointerEvents = "none";
      }
    }, [isOpen]);
  
    useEffect(() => {
      const SubMenuButton = refSubMenuButton.current;
      const SubMenuList = refSubMenuList.current;
  
      addEL(SubMenuButton, "mouseenter", subMenuButtonEnterHandle);
      addEL(SubMenuList, "mouseenter", subMenuButtonEnterHandle);
      addEL(SubMenuButton, "mouseleave", subMenuButtonLeaveHandle);
      addEL(SubMenuList, "mouseleave", subMenuButtonLeaveHandle);
  
      return () => {
        remEL(SubMenuButton, "mouseenter", subMenuButtonEnterHandle);
        remEL(SubMenuList, "mouseenter", subMenuButtonEnterHandle);
        remEL(SubMenuButton, "mouseleave", subMenuButtonLeaveHandle);
        remEL(SubMenuList, "mouseleave", subMenuButtonLeaveHandle);
      };
    }, [subMenuButtonEnterHandle, subMenuButtonLeaveHandle]);
  
    return (
      <Menu autoSelect={false} offset={[0, 0]} isOpen={isOpen} placement="right">
        <MenuButton
          ref={refSubMenuButton}
          rightIcon={<ChevronDownIcon />}
          as={Button}
          w="full"
          textAlign="left"
          borderRadius="none"
          border="none"
          bg="white"
          zIndex={999}
          _hover={{
            bg: "blue.50"
          }}
          _focus={{
            boxShadow: "none"
          }}
          _expanded={{
            bg: "blue.300",
            color: "white"
          }}
        >
          {title}
        </MenuButton>
        <MenuList
          minW={0}
          ref={refSubMenuList}
          zIndex={999}
          _hover={{ zIndex: 1000 }}
        >
          {childrenItems.map((item, i) => {
            i;
            if (typeof item === "string") {
              return <StyledMenuItem setSelectedItem={setSelectedItem} item={item} key={item}>{item}</StyledMenuItem>;
            }
            return <MenuItemSubMenu setSelectedItem={setSelectedItem} item={item}  key={item}>{item}</MenuItemSubMenu>;
          })}
        </MenuList>
      </Menu>
    );
  };
  
  export default InnerMenu;
  
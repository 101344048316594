import { Box } from "@chakra-ui/layout";
import React, { useEffect, useRef } from "react";

const CloseMenuOverlay = ({ isActive, setInnerMenusActive }) => {
  const refMenuOverlay = useRef();

  useEffect(() => {
    var refMenu = refMenuOverlay.current;

    if (refMenu) {
      refMenu.addEventListener(
        "mouseenter",
        setInnerMenusActive(false)
      );
    } else 

    return () => {
      if (refMenu) {
        refMenu.removeEventListener(
          "mouseenter",
          setInnerMenusActive(false)
        );
      }
    };
  }, [isActive, setInnerMenusActive]);

  return (
    <Box
      ref={refMenuOverlay}
      position="fixed"
      pointerEvents={isActive ? "auto" : "none"}
      w="100vw"
      h="100vh"
      zIndex={999}
    ></Box>
  );
};

export default CloseMenuOverlay;
import React, { createContext, useContext, useState, useEffect } from 'react';
import FastAPIClient from '../client';
import config from '../config';

const client = new FastAPIClient(config);
const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(localStorage.getItem('authToken') || null);

  const login = async (username, password) => {
    const token = await client.login(username, password);

    if (token) {
        localStorage.setItem("authToken", token);
        setUser(token);
        return true;
    } else {
      throw new Error('Login failed');
    }
  };

  const logout = () => {
    localStorage.removeItem('authToken');
    setUser(null);
  };

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      client.fetchUser(token)
        .then(({ data }) => {
            setUser(token);
            return data;
        })
        .catch((error) => {
            console.error("Fetching user failed:", error);
            // Handle the error here
        });
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// import runtimeEnv from '@mars/heroku-js-runtime-env';

// const env = runtimeEnv();
const config = {
  // apiBasePath: 'https://vnxe47ml97.execute-api.us-east-2.amazonaws.com/dev' || '',
  apiBasePath: process.env.REACT_APP_BACKEND_BASE_URL || "",
  reactAppMode: process.env.REACT_APP_MODE || "dev",
  googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID
};

export default config;

import React from 'react';
import { HStack, Select } from '@chakra-ui/react'; // Assuming you are using Chakra UI

// Function to extract the number from the string
const extractNumber = (str) => {
    const match = str.match(/^(\d+)/);
    return match ? match[1] : '';
}; 

const ChatConnectorSelector = ({
  googleProfile,
  selectedChatConnector,
  // setSelectedChatConnector,
  selectedGoogleLoginCustomerID,
  setSelectedGoogleLoginCustomerID,
  selectedGoogleCustomerID,
  setSelectedGoogleCustomerID,
  setChatConnectorProfile,
  googleLoginCustomerIds,
  googleCustomerIds,
}) => {
  return (
    <div>
    <HStack spacing={4} align="center" mt={1}>
      {/* <Select
        value={selectedChatConnector} 
        onChange={(e) => setSelectedChatConnector(e.target.value)}>
            <option value="" disabled>Select connector</option>
            {googleProfile && <option value="googleapi">Google Ads</option>}
      </Select> */}

      {googleProfile && selectedChatConnector === 'googleapi' && (
        <Select
          value={selectedGoogleLoginCustomerID} 
          onChange={(e) => {
            if (e.target.value !== '') {
              setSelectedGoogleLoginCustomerID(e.target.value);
            }
        }}>
          <option value='' disabled>Select a Login Customer ID</option>
          {googleLoginCustomerIds.map(id => (
            <option key={id} value={id}>
              {id}
            </option>
          ))}
        </Select>
      )}

      {googleProfile && selectedChatConnector === 'googleapi' && googleCustomerIds && (
        <Select
            value={selectedGoogleCustomerID} 
            onChange={(e) => {
              if (e.target.value !== '') {
                setSelectedGoogleCustomerID(e.target.value);
                setChatConnectorProfile(true);
              }
        }}>
          <option value="" disabled>Select a Customer ID</option>
          <option value="1">N/A</option>
          {googleCustomerIds.map(id => (
            <option key={id} value={extractNumber(id)}>
              {id}
            </option>
          ))}
        </Select>
      )}


    </HStack>
            {/* Select Campaign Intent: */}
        {/* <Select>
        <option value="" selected>Conversions</option>
      </Select> */}
      
    </div>
  );
};

export default ChatConnectorSelector;

{/* <HStack spacing={4} align="center" mt={1}>
<Select defaultValue="" value={selectedChatConnector} onChange={(e) => {setSelectedChatConnector(e.target.value)}}>
  <option value="">Select connector</option>
    {googleProfile &&
      <option value="googleapi">Google Ads</option>
    }
</Select>                

{googleProfile && selectedChatConnector === 'googleapi' && (
  <Select defaultValue="" value={selectedGoogleLoginCustomerID} onChange={(e) => {if (e.target.value != '') {
    setSelectedGoogleLoginCustomerID(e.target.value)
  }}}>
    <option value="" disabled>Select a Login Customer ID</option>
    {googleLoginCustomerIds.map(id => (
      <option key={id} value={id}>
          {id}
      </option>
    ))}
  </Select>
)}

{googleProfile && selectedChatConnector === 'googleapi' && googleCustomerIds && (
  <Select defaultValue="" value={selectedGoogleCustomerID} onChange={(e) => {if (e.target.value != '') {
    setSelectedGoogleCustomerID(e.target.value);
    setChatConnectorProfile(true);
  }}}>
    <option value="" disabled>Select a Customer ID</option>
    <option value="1">N/A</option>
    {googleCustomerIds.map(id => (
        <option key={id} value={extractNumber(id)}>
            {id}
        </option>
    ))}
  </Select>
)}
</HStack> */}
import React, { useEffect, useState } from 'react';
import {
    Flex,
    Menu,
    MenuButton,
    MenuList,
    Button,
  } from '@chakra-ui/react'
import { ChevronDownIcon } from "@chakra-ui/icons";

import InnerMenu from './InnerMenu';
import CloseMenuOverlay from './CloseMenuOverlay';
import MenuItemSubMenu from './MenuItemSubMenu';
import StyledMenuItem from './MenuItem';

function formatDate(date) {
    if (!date) {
        return date;
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${day}-${month}-${year}`;
}

function calculateTimePeriod(timePeriod) {
    timePeriod = timePeriod.toLowerCase();
    let startDate = new Date();
    let endDate = new Date();

    switch (timePeriod) {
        case 'today':
            break;

        case 'yesterday':
            startDate.setDate(startDate.getDate() - 1);
            endDate.setDate(endDate.getDate() - 1);
            break;            

        case 'last week':
            startDate.setDate(startDate.getDate() - 7 - startDate.getDay() + 1);
            endDate.setDate(endDate.getDate() - endDate.getDay());
            break;

        case 'last 2 weeks':
            startDate.setDate(startDate.getDate() - 14 - startDate.getDay() + 1);
            endDate.setDate(endDate.getDate() - endDate.getDay());
            break;

        case 'last month':
            startDate.setDate(1);
            startDate.setMonth(startDate.getMonth() - 1);
            endDate.setDate(0);
            break;

        case 'last 30 days':
            startDate.setDate(startDate.getDate() - 29);
            break;

        case 'last 3 months':
            startDate.setMonth(startDate.getMonth() - 3);
            startDate.setDate(1);
            endDate.setDate(0);
            break;

        case 'last year':
            startDate.setFullYear(startDate.getFullYear() - 1);
            startDate.setMonth(0);
            startDate.setDate(1);
            endDate.setMonth(11);
            endDate.setDate(31);
            break;

        case 'year to date':
            startDate.setMonth(0);
            startDate.setDate(1);
            break;

        default:
            return [false, false];
    }

    return [formatDate(startDate), formatDate(endDate)];
}

const QuestionDropdownMenu = ({setMessage, fromValue, setFromValue, toValue, setToValue, testUser, questionMode, campaignList, setSelectedCampaign}) => {
  const [selectedItem, setSelectedItem] = useState('');
  const [selectedPeriod, setSelectedPeriod] = useState("Last 2 Weeks");

  const [selectedCampaignValue, setSelectedCampaignValue] = useState(false);
  const [innerMenusActive, setInnerMenusActive] = useState(true);

  const periods = ['Today', 'Yesterday', 'Last Week', 'Last 2 Weeks', 'Last Month', 'Last 30 Days', 'Last 3 Months', 'Last Year', 'Year to date', 'Other']
  const [periodFromValue, setPeriodFromValue] = useState(fromValue);
  const [periodToValue, setPeriodToValue] = useState(toValue);

  const closeSubMenus = () => {
    setInnerMenusActive(false);
  };

  useEffect(() => {
    setMessage(selectedItem)
  }, [selectedItem, setMessage]);

  useEffect(() => {
    if (fromValue != periodFromValue) {
        setSelectedPeriod('Other');
    }
  }, [fromValue, periodFromValue]);
 
  useEffect(() => {
    if (toValue != periodToValue) {
        setSelectedPeriod('Other');
    }
  }, [toValue, periodToValue]);


  useEffect(() => {
    const [from, to] = calculateTimePeriod(selectedPeriod);
    setPeriodFromValue(from);
    setPeriodToValue(to);

    if (from && to) {
        setFromValue(from);
        setToValue(to);
    }

  }, [selectedPeriod, setFromValue, setToValue]);

  useEffect(() => {
    if (selectedCampaignValue) {
        setSelectedCampaign(selectedCampaignValue);
    }
  }, [selectedCampaignValue, setSelectedCampaign]);
  
  return (
    <Flex
        justifyContent="center"
    >
        <Menu closeOnSelect={false} isLazy>
            <CloseMenuOverlay
            isActive={innerMenusActive}
            setInnerMenusActive={setInnerMenusActive}
            />
            <MenuButton zIndex={999} as={Button} rightIcon={<ChevronDownIcon />}>
                {selectedItem || "Select a Question"}
            </MenuButton>
            <MenuList transition="all 0.1s" zIndex={999}>
                <MenuItemSubMenu>
                    <InnerMenu
                        setSelectedItem={setSelectedItem}
                        title="Campaigns"
                        childrenItems={[
                            "What are the top performing campaign(s)?",
                            "What are the underperforming ad groups in my campaigns?",
                            "Which campaigns have budget cap, but have good efficiency?",
                        ]}
                    />
                </MenuItemSubMenu>
                <MenuItemSubMenu>
                    <InnerMenu
                        setSelectedItem={setSelectedItem}
                        title="Callouts"
                        childrenItems={[
                            "What changes were done on the account?",
                            "Why did the cost per acquisition (CPA) change?",
                            "What is the return on ad spend (ROAS)?",
                            "What is the compared return on ad spend (cROAS)?",
                            "What are the CPAs for each conversion actions?",
                        ]}
                    />
                </MenuItemSubMenu>
                <MenuItemSubMenu>
                    <InnerMenu
                        setSelectedItem={setSelectedItem}
                        title="Headlines"
                        childrenItems={[
                            "What are the best headlines?",
                            "Which headlines are not working?",
                        ]}
                        closeSubMenu={closeSubMenus}
                    />
                </MenuItemSubMenu>
                <MenuItemSubMenu>
                    <InnerMenu
                        setSelectedItem={setSelectedItem}
                        title="Keywords"
                        childrenItems={[
                            "What are the best performing keywords?",
                            "What keywords are performing well but don't matter?",
                            "What are worst performing keywords?",
                            "Suggest some keyword ideas I can test based on this keywords?",
                        ]}
                        closeSubMenu={closeSubMenus}
                    />
                </MenuItemSubMenu>
                <MenuItemSubMenu>
                    <InnerMenu
                        setSelectedItem={setSelectedItem}
                        title="Search Terms"
                        childrenItems={[
                            "What are search terms that actually matter?",
                            "What are search terms that don't already exist as keywords?",
                        ]}
                        closeSubMenu={closeSubMenus}
                    />
                </MenuItemSubMenu>
                <MenuItemSubMenu>
                    <InnerMenu
                        setSelectedItem={setSelectedItem}
                        title="Bidding & Budgeting"
                        childrenItems={[
                            "What are the most effective times during the week to reach high intent customers? ",
                        ]}
                        closeSubMenu={closeSubMenus}
                    />
                </MenuItemSubMenu>
                <MenuItemSubMenu>
                    <InnerMenu
                        setSelectedItem={setSelectedItem}
                        title="Visualization"
                        childrenItems={[
                            "Show my account trend",
                            "Show my campaign trend",
                        ]}
                        closeSubMenu={closeSubMenus}
                    />
                </MenuItemSubMenu>
                { testUser && (
                    <MenuItemSubMenu>
                        <InnerMenu
                            setSelectedItem={setSelectedItem}
                            title="Live Preview"
                            childrenItems={[
                                "How are non-branded keywords performing well relative to branded keywords?",
                                "This is a sample test quesion. Do not use.",
                            ]}
                            closeSubMenu={closeSubMenus}
                        />
                    </MenuItemSubMenu>
                )}

                {/* <StyledMenuItem setSelectedItem={setSelectedItem} item={'About'} closeSubMenu={closeSubMenus}>About</StyledMenuItem>
                <MenuItemSubMenu>
                    <InnerMenu
                    setSelectedItem={setSelectedItem}
                    title="Books"
                    childrenItems={[
                        "Textbooks",
                        <InnerMenu
                        setSelectedItem={setSelectedItem}
                        key="Books"
                        title="Children's Books"
                        childrenItems={[
                            "Happy Birthday to You!",
                        ]}
                        />
                    ]}
                    />
                </MenuItemSubMenu> */}
            </MenuList>
        </Menu>
        
        {questionMode == 'selectMultipleDropdownCampaign' && (
            <Menu>
                <MenuButton zIndex={900} as={Button} rightIcon={<ChevronDownIcon />}>
                    {selectedCampaignValue || "Select a Campaign"}
                </MenuButton>
                <MenuList transition="all 0.1s" zIndex={900} maxH="400px" overflowY="auto">
                    {campaignList.map((campaign) => (
                        <StyledMenuItem key={campaign} setSelectedItem={setSelectedCampaignValue} item={campaign}>
                            {campaign}
                        </StyledMenuItem>
                    ))}
                </MenuList>
                
            </Menu>
        )}

        {(questionMode == 'default' ||  questionMode == 'selectMultipleDropdownCampaign' ||  questionMode == 'selectMultipleDropdown') && (
            <Menu>
                <MenuButton zIndex={900} as={Button} rightIcon={<ChevronDownIcon />}>
                    {selectedPeriod || "Last 2 Weeks"}
                </MenuButton>
                <MenuList transition="all 0.1s" zIndex={900}>
                    {periods.map((period) => (
                        <StyledMenuItem key={period} setSelectedItem={setSelectedPeriod} item={period}>
                            {period}
                        </StyledMenuItem>
                    ))}
                </MenuList>
                
            </Menu>
        )}
    </Flex>
  );
};

export default QuestionDropdownMenu;

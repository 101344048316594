import React from "react";
import { createRoot } from "react-dom/client";
// import ReactDOM from 'react-dom';
import "./output.css";
import App from "./App";

import { extendTheme, ChakraProvider } from "@chakra-ui/react";

import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTAG_ID || "" 
};

TagManager.initialize(tagManagerArgs);

const colors = {
  brand: {
    base: "#4f46e5", //rgb(79 70 229)
  },

  backgroundColorChat: {
    user: "",
    ai: "",
  },
};

const theme = extendTheme({ colors });

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>
);

import React from 'react';

// Assuming buttonAction is passed as a prop
const SideMenuButton = ({ buttonAction, text }) => {
  return (
    <button
      onClick={buttonAction}
      style={{ width: "92%" }}
      className="my-1 rounded-lg bg-indigo-600 text-white hover:bg-transparent py-2"
    >
      {text}
    </button>
  );
};

export default SideMenuButton;

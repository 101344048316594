import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Heading, Text } from "@chakra-ui/react";
import Footer from "../../components/Footer/footer";

import ModalContextProvider from '../../contexts/ModalContext';

import Modal from '../../components/Modal';
import Navbar from '../../components/NavigationBar/Navbar';

const BlogPost = () => {
    const { slug } = useParams();

    const [postContent, setPostContent] = useState(null);

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const postData = await import(`../../posts/${slug}.json`);

                if (postData.header_image) {
                    const image = require(`../../assets/images/${postData.header_image}`)
                    postData.header_image_src = image;
                }
                setPostContent(postData);

            } catch (error) {
                console.error("Error loading post:", error);
                setPostContent(null);
            }
        };

        fetchPost();
    }, [slug]);

    if (!postContent) {
        return <p>Blog not found</p>;
    }

    return (
        <ModalContextProvider>
            <Navbar />
            <Box className="bg-zinc-200">
                <Box maxWidth="55%" m="0 auto">
                    <Box as="header" pt="200px" mb={20} z-5>
                        <Heading as="h1" size="xl" mb={4}>
                            <Text>{postContent.title}</Text>
                        </Heading>
                    </Box>
                    <Text fontSize="sm" color="#555" mb={2}>
                        <em>{postContent.date} by {postContent.author}</em>
                    </Text>

                    {postContent.header_image_src && <img src={postContent.header_image_src} alt="header image" />}
                    <Text mt={4} paddingBottom={25} dangerouslySetInnerHTML={{ __html: postContent.content }} />
                </Box>
            </Box>
            <Footer />
            <Modal />
        </ModalContextProvider>
    );
};

export default BlogPost;

import React, { useState, useEffect, useRef } from 'react';
import { ChatIcon, PencilAltIcon, TrashIcon } from "@heroicons/react/solid";
import ContentEditable from "./content-editable";

const getChatTitle = (chat) => {
  if (chat.description) {
    return chat.description
  
  } 
  // else if (chat.chats.length > 1) {
  //   return chat.chats[1].message
  // }
    
  return "New Chat"
}

const getDisplayTitle = (title) => {
  if (title.length > 25){
    return title.substring(0, 25) + "..."
  }

  return title
}

function ChatGroupItem({chat, handleDeleteChatGroup, handleChatGroupClick, handleRenameChatGroup, chatGroup}){
  const [title, setTitle] = useState(getChatTitle(chat));
  const [displayTitle, setDisplayTitle] = useState(getDisplayTitle(title));
  const [editedText, setEditedText] = useState(title);

  useEffect(() => {
    setDisplayTitle(getDisplayTitle(title));
  }, [title])

  const [isEditing, setIsEditing] = useState(false);
  const contentEditableRef = useRef(null);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  // https://github.com/lovasoa/react-contenteditable/issues/161#issuecomment-1811847955
  const handleBlur = (id) => {
    setIsEditing(false);
    console.log(editedText)
    if (window.confirm('Save change?')){
      let newTitle = contentEditableRef.current.innerHTML;
      setTitle(newTitle);
      handleRenameChatGroup(id, newTitle);
    } else {
      handleCancelClick();
    }
  };

  const handleCancelClick = () => {
    // Cancel the edit and reset the edited text to the initial value.
    setIsEditing(false);
    setTitle(title);
    setEditedText(title);
  };

  return (
    <span
      key={chat.id}
      onClick={() => handleChatGroupClick(chat)}
      className={`my-1 flex cursor-pointer flex-row items-center rounded-md px-2 hover:bg-slate-700 ${
        chat.id === chatGroup ? "bg-slate-700" : ""
      }`}
    >
      <ChatIcon className="h-5" />
      {chatGroup === chat.id ? (
        <div>
          {isEditing ? (
            <li style={{ display: "inline-flex" }}>
              <ContentEditable
                innerRef={contentEditableRef}
                onBlur={() => handleBlur(chat.id)}
                disabled={false}
                html={editedText}
              />
              {/* <CheckIcon className="h-5 w-5 ml-2 cursor-pointer" style={{ fontSize: "16px" }} onClick={() => handleSaveClick(chat.id)}/> */}
              {/* <StopIcon className="h-5 w-5 ml-2 cursor-pointer" style={{ right: "0", fontSize: "16px" }} onClick={handleCancelClick} /> */}
            </li>
          ) : (
            <li style={{ display: "inline-flex" }}>
              <p
                style={{
                  width: "100px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {displayTitle}
              </p>
              <PencilAltIcon
                className="ml-2 h-5 w-5 cursor-pointer"
                onClick={handleEditClick}
              />
              <TrashIcon
                className="ml-2 h-5 w-5 cursor-pointer"
                style={{ right: "0" }}
                onClick={() => handleDeleteChatGroup(chat.id)}
              />
            </li>
          )}
        </div>
      ) : (
        <li>
          <p
            style={{
              width: "130px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {displayTitle}
          </p>
        </li>
      )}
    </span>
  );

}

const ChatTitleList = ({ chatData, setChatGroup, setChatGroupConnector, handleDeleteChatGroup, handleRenameChatGroup }) => {
  const [chatGroup, setChatGroupState] = useState(null);

  const handleChatGroupClick = (chatData) => {
    setChatGroupState(chatData.id);
    setChatGroup(chatData.id);
    setChatGroupConnector(chatData);
  };

  return (
    <ul className="mb-6 mt-2 max-h-[50px] overflow-y-auto">
      {chatData.map((chat) => (
        <ChatGroupItem
          key={chat.id}
          chat={chat}
          handleDeleteChatGroup={handleDeleteChatGroup}
          handleChatGroupClick={handleChatGroupClick}
          handleRenameChatGroup={handleRenameChatGroup}
          chatGroup={chatGroup}
        />
      ))}
    </ul>
  );
};

export default ChatTitleList;
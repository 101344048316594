import React, { useState, useEffect, useRef, useCallback } from "react";
import { useLocation } from 'react-router-dom';

import {
  Box,
  Button,
  Heading,
  HStack,
  VStack,
  Input,
  InputGroup,
  InputRightElement,
  Text, Flex,
  useDisclosure,
  Modal, ModalOverlay, ModalContent,
  ModalHeader, ModalFooter, ModalBody,
  // Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon,
  Popover, PopoverTrigger, PopoverContent, PopoverBody
} from "@chakra-ui/react";

import { useIdleTimer } from 'react-idle-timer/legacy'

import VerifiedLogo from '../assets/images/google_verified.png'
import jwtDecode from "jwt-decode";
import FastAPIClient from "../client";
import config from "../config";

import { format, subDays } from 'date-fns';
import DateRangePicker from "./ChatSetting/DatePicker";
import ChatConnectorSelector from "./ChatSetting/ChatConnector";
import ChatSummary from "./ChatSummary/chatsummary";
// import MarketerInvite from "./MarketerInvite";
import QuestionDropdownMenu from './Question/Question';

import { NotLoggedIn } from "./ChatDashboard/chatdashboard";
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import SideMenuButton from "./ChatSideMenu/SideMenuButton";
import ChatTitleList from "./ChatSideMenu/ChatTitleList";

import DashboardAlertModal from "./Modal/DashboardAlertModal";
import StripePricingModal from "./Modal/StripePricingModal";

import { useAuth } from "../contexts/AuthContext";
import DataChartDropdownMenuComponent from "./DataChatMenu/DropdownMenu";
import ProfileMenu from "./ProfileIcon/ProfileMenu";
const axios = require("axios");
const client = new FastAPIClient(config);

const checkTokenExpiry = (userToken) => {
  if (!userToken) { return false; }

  const decodedAccessToken = jwtDecode(userToken.access_token);
  return decodedAccessToken.exp > new Date()/1000;
};

function searchStringContainsSubstring(str, array) {
  return array.some(element => element.includes(str));
}

const Dashboard = () => {
  const timeout = 3_600_000
  const promptBeforeIdle = 100_000

  const [state, setState] = useState('Active')
  const [remaining, setRemaining] = useState(timeout)
  const [open, setOpen] = useState(false)

  const onIdle = () => {
    setState('Idle')
    setOpen(false)
  }

  const onActive = () => {
    setState('Active')
    setOpen(false)
  }

  const onPrompt = () => {
    setState('Prompted')
    setOpen(true)
  }

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })

  const handleStillHere = () => {
    activate()
  }
  
  const { user, logout } = useAuth();
  
  const [initial, setInitial] = useState("");
  const [isTestUser, setIsTestUser] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = JSON.parse(user);
    const expiredAccessToken = checkTokenExpiry(token)

    if ( user && expiredAccessToken ) {
      if ( 'initial' in token ) {
        setInitial(token.initial.toUpperCase());
      } else {
        setInitial('Me');
      }

      if ( 'testUser' in token ) {
        setIsTestUser(token.testUser);
      }
      setLoading(false);
    }  
  }, [user])

  const [dashboardAlertModalReason, setDashboardAlertModalReason] = useState('');
  const [chatGroup, setChatGroup] = useState(null);
  const [chatData, setChatData] = useState([]);
  const [message, setMessage] = useState("");

  const [canAsk, setCanAsk] = useState(true);

  const [ selectedChatConnector, setSelectedChatConnector] = useState('');

  const [ googleUser, setGoogleUser] = useState(null);
  const [ googleProfile, setGoogleProfile] = useState(sessionStorage.getItem('googleProfile') || '');

  const [ googleLoginCustomerIds, setGoogleLoginCustomerIds] = useState([]);
  const [ selectedGoogleLoginCustomerID, setSelectedGoogleLoginCustomerID] = useState('')
  const [ googleCustomerIds, setGoogleCustomerIds] = useState([]);
  const [ selectedGoogleCustomerID, setSelectedGoogleCustomerID] = useState('')
  const isManualUpdate = useRef(false)

  const [ chatConnectorProfile, setChatConnectorProfile] = useState(false);
  const [ questionMode, setQuestionMode] = useState('default');

  const [ campaignList, setCampaignList ] = useState([]);
  const [ selectedCampaign, setSelectedCampaign ] = useState('');
  const [ isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [ selectedDataCharts, setSelectedDataCharts ]= useState(Array(4).fill(''));
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  }

  const [ textboxInputValue, setTextboxInputValue] = useState("");

  const handletextboxInputChange = (e) => {
    setTextboxInputValue(e.target.value);
  }

  const defaultSelected = {
    from: subDays(new Date(), 14),
    to: new Date(),
    compareFrom: subDays(new Date(), 28),
    compareTo: subDays(new Date(), 15),
  };

  const [compareFromValue, setCompareFromValue] = useState(format(defaultSelected.compareFrom, 'dd-MM-yyyy'));
  const [compareToValue, setCompareToValue] = useState(format(defaultSelected.compareTo, 'dd-MM-yyyy'));
  const [fromValue, setFromValue] = useState(format(defaultSelected.from, 'dd-MM-yyyy'));
  const [toValue, setToValue] = useState(format(defaultSelected.to, 'dd-MM-yyyy'));

  // Move from here to components
  useEffect(() => {
    if (chatGroup) {
      fetchUserChats(chatGroup);
    } else {
      fetchUserChats();
    }
  }, [chatGroup]);

  const fetchUserChats = async (id=-1) => {
    await client.getChatGroup("general", id).then((data) => {
      setChatData(data.results);
    });
  };
  // to here

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      // console.log(codeResponse, 'aaa')
      try {
          const token = await client.loginGoogle(codeResponse)
          setGoogleUser(token);
          // sessionStorage.setItem('googleUser', token);
      } catch (error) {
        // Handle any errors that occur during the login process
        console.error('Error during login:', error);
      }
    },
    onError: (error) => console.log('Login Failed:', error),
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/adwords'
});

  const handleSelectGoogleLoginCustomerIDByNumber = (number) => {
    // Loop through the array and find the object containing the number
    const matchingItem = googleLoginCustomerIds.find(item => {
      const match = item.match(/\((\d+)\)/); // Extract the number inside parentheses
      return match && match[1] === number.toString(); // Compare with the given number
    });

  if (matchingItem) {
    setSelectedGoogleLoginCustomerID(matchingItem);
  } else {
    console.log("Number not found");
  }
};

useEffect( () => {
  if (googleUser) {
      axios
        .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${googleUser}`, {
            headers: {
                Authorization: `Bearer ${googleUser}`,
                Accept: 'application/json'
            }
        })
        .then((res) => {
          // console.log(res.data)
            setGoogleProfile(res.data.email);
            sessionStorage.setItem('googleProfile', res.data.email);
            setSelectedChatConnector('googleapi');
        })
        .catch((err) => console.log(err));
  }
}, [googleUser]);

useEffect(() => {
  resetGoogleConnector();
}, []);

const resetGoogleConnector = () => {
  setGoogleLoginCustomerIds([]);
  setSelectedGoogleLoginCustomerID('');
  setGoogleCustomerIds([]);
  setSelectedGoogleCustomerID('');
  setQuestionMode('default');
};

// Gets all the clients associated with the google account
useEffect(() => {
  if (isManualUpdate.current) {
    return;
  }

  resetGoogleConnector();
  if ( googleProfile ) {
    setSelectedChatConnector("googleapi")
    client.getGoogleLoginCustomerIds()
      .then(data => setGoogleLoginCustomerIds(data));
  }
}, [googleProfile]);

// Update values in the Google customers dropdown (third one) based on the selected value in the second
useEffect(() => {
  if (isManualUpdate.current) {
    return;
  }

// IsValidGoogleLoginCustomerIDNumber is quick patch to stop the double call when selecting from the chat list
  const match = selectedGoogleLoginCustomerID.match(/\((\d+)\)/);
  
  if (selectedGoogleLoginCustomerID != '' && match) {
    client.getGoogleCustomerIds(selectedGoogleLoginCustomerID)
      .then(data => setGoogleCustomerIds(data));
  } else {
    setGoogleCustomerIds([]);
  }
}, [selectedGoogleLoginCustomerID])

useEffect(() => {
  if (isManualUpdate.current) {
    return;
  }

  if (!searchStringContainsSubstring(selectedGoogleCustomerID, googleCustomerIds) && selectedGoogleCustomerID != 1 && selectedGoogleCustomerID != ''){
    setSelectedGoogleCustomerID('');
  }
}, [googleCustomerIds, selectedGoogleCustomerID])

useEffect(() => {
  if (selectedChatConnector == "googleapi" && selectedGoogleLoginCustomerID && selectedGoogleCustomerID && chatGroup) {
    setChatConnectorProfile(true)
  } else {
    setChatConnectorProfile(false)
  }
}, [selectedChatConnector, selectedGoogleLoginCustomerID, selectedGoogleCustomerID, chatGroup])


// Pass mode to backend
// Add period 2 to backend
// Update period 2 by default
useEffect(() => {
  if (!message){
    setQuestionMode('default');
  } else if (message.includes("cROAS")){
    setQuestionMode('compareDate');
  } else if (message.includes("keyword ideas")){
    setQuestionMode('textBox')
  } else if (message.includes("my campaign trend")){
    const setChatCampaignNames = () => {
      if (!selectedGoogleLoginCustomerID || !selectedGoogleCustomerID)
        {
          setCampaignList([]);
        }
    
      client.getGoogleCampaignNames(selectedGoogleLoginCustomerID, selectedGoogleCustomerID)
        .then(data => setCampaignList(data))
    };
  
    setQuestionMode('selectMultipleDropdownCampaign')
    setChatCampaignNames();
  } else if (message.includes('my account trend')){
    setQuestionMode('selectMultipleDropdown')
  } else {
    setQuestionMode('default');
  }
}, [message, selectedGoogleCustomerID, selectedGoogleLoginCustomerID])

useEffect(() => { 
  if (questionMode != "textBox") {
    setTextboxInputValue("")
  } 
  if (questionMode != "selectMultipleDropdownCampaign") {
    setCampaignList([]);
  }

  if (questionMode != "selectMultipleDropdownCampaign" && questionMode != "selectMultipleDropdown") {
    setSelectedDataCharts(Array(4).fill(''));
  }
}, [questionMode]);

const handleGoogleLogOut = useCallback(() => {
    googleLogout();
    sessionStorage.removeItem('googleProfile', googleProfile);
    setGoogleProfile('');
  }, [googleProfile]);

  // same here
  const handleLogout = useCallback(() => {
    logout();
  }, [logout]);

  useEffect(() => {
    if (state == 'Idle'){
      handleGoogleLogOut()
      handleLogout()
    }
  }, [handleGoogleLogOut, handleLogout, state]);

  const handleDeleteChatGroup = async (id) => {
    if (window.confirm('Are you sure you want to delete this chat?')){
      await client.deleteChatGroup(id).then(() => {
        fetchUserChats();
        setChatGroup(null);
      });
    }
  };

  const handleRenameChatGroup = async (chatID, title) => {
    await client.renameChatGroup(chatID, title).then(() => {
      fetchUserChats(chatID);
    });
  };

  const setChatGroupConnector = (chatGroupData) => {
    // Update when google profile is updated -- not included

    if (!chatGroupData.connector || !chatGroupData.google_login_customer_id || !chatGroupData.google_customer_id || !googleProfile)
    {
        return false;
    }

    isManualUpdate.current = true;
    setSelectedChatConnector(chatGroupData.connector);
    handleSelectGoogleLoginCustomerIDByNumber(chatGroupData.google_login_customer_id);

    client.getGoogleCustomerIds(chatGroupData.google_login_customer_id)
      .then(data => setGoogleCustomerIds(data))

    // update customer list
    
    if (searchStringContainsSubstring(chatGroupData.google_customer_id, googleCustomerIds) || chatGroupData.google_customer_id == 1){
      setSelectedGoogleCustomerID(chatGroupData.google_customer_id);
    } else {
      setSelectedGoogleCustomerID('');
    }
    
    // Request to verify that connector is still valid? or handle in request

    isManualUpdate.current = false;
  };
  
  const resetChatGroup = async () => {
    setQuestionMode('default');
    setSelectedGoogleLoginCustomerID('');
    setSelectedGoogleCustomerID('');
    setChatGroup(null);
  };

  const handleNewChat = async () => {
    if (!selectedGoogleLoginCustomerID || !selectedGoogleCustomerID){
      alert('Please select a Login Customer and Customer ID from the dropdown menu.');
      return;
    }

    await client
      .createNewChat(selectedChatConnector)
      .then((data) => {
        setChatGroup(data.id);
      })
      .catch((err) => {
        // setLoading(false)
        // setError(true);
        alert(err);
      });
  };

  const handleAskQuestion = async (e) => {
    e.preventDefault();
    
    if (message === "") {
      return;
    }

    var questionData = { 'connector': selectedChatConnector, 'login_customer_id': selectedGoogleLoginCustomerID, 
        'customer_id': selectedGoogleCustomerID, 'start': fromValue, 'end': toValue, 'mode': questionMode};

    if (questionMode === 'compareDate') {
      questionData['compare'] = true;
      questionData['compareStart'] = compareFromValue;
      questionData['compareEnd'] = compareToValue;
    } else if (questionMode === 'textBox') {
      questionData['textbox'] = true;
      questionData['textboxValue'] = textboxInputValue;

      if (!textboxInputValue || textboxInputValue.trim() === '') {
        window.alert('Please enter a keyword idea.');
        return;
      }
    } else if (questionMode === 'selectMultipleDropdownCampaign') {
      questionData['campaign'] = selectedCampaign;
      questionData['dataCharts'] = selectedDataCharts;
      
      if (selectedDataCharts.every(element => element === '')) {
        window.alert('Please select a metric to display on the graph.');
        return;
      }
    } else if (questionMode === 'selectMultipleDropdown') {
      questionData['dataCharts'] = selectedDataCharts;

      if (selectedDataCharts.every(element => element === '')) {
        window.alert('Please select a metric to display on the graph.');
        return;
      }
    }
    
    setCanAsk(false);
    await client
      .askQuestion(message, chatGroup, false, JSON.stringify(questionData))
      // eslint-disable-next-line no-unused-vars
      .then((data) => {
        if (data === 'User not subscribed.') {
          setDashboardAlertModalReason('unsubscribed');
          alertModalOnOpen()
        } else {
        // if (data == 'error') {
        //     console.log('error handling message')
        // }
          fetchUserChats(chatGroup);
          setCanAsk(true);
        }
      });
  };

  const { isOpen:stripePricingModalIsOpen, onOpen:stripePricingModalOnOpen, onClose:stripePricingModalOnClose } = useDisclosure();
  const { isOpen:alertModalIsOpen, onOpen:alertModalOnOpen, onClose:alertModalOnClose } = useDisclosure();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const orderValue = queryParams.get('order');

    if (orderValue) {
      setDashboardAlertModalReason(orderValue);
      alertModalOnOpen()
    }
  }, [alertModalOnOpen, location]);

  if (loading) {
    return <NotLoggedIn />
  } 

  return (
    <div
      className="grid grid-flow-col grid-cols-12"
      style={{
        position: "relative",
        height: "100vh",
        background: "rgb(228 228 231)",
      }}
    >
      <Modal blockScrollOnMount={false} onClose={handleStillHere} isOpen={open} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Are you still here? </ModalHeader>
          <ModalBody>
            Logging out in {remaining} seconds
          </ModalBody>
          <ModalFooter>
            <Flex justify="center" width="100%">
              <Button onClick={handleStillHere}>Im still here</Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <DashboardAlertModal
        reason={dashboardAlertModalReason}
        isOpen={alertModalIsOpen} onClose={alertModalOnClose}
      />
      
      <StripePricingModal 
        isOpen={stripePricingModalIsOpen} onClose={stripePricingModalOnClose}
      />
      <div
        style={{ position: "relative" }}
        className="col-span-2 h-screen w-full bg-slate-900 p-3 text-gray-300"
      >

        {googleProfile && (
          <div
            className="flex flex-col"
            style={{
              height: "85vh",
            }}
          >
            {chatConnectorProfile && <SideMenuButton buttonAction={resetChatGroup} text={"New Chat"} /> }
            <ChatTitleList
              chatData={chatData}
              setChatGroup={setChatGroup}
              setChatGroupConnector={setChatGroupConnector}
              handleDeleteChatGroup={handleDeleteChatGroup}
              handleRenameChatGroup={handleRenameChatGroup}
            />
          </div>
        )}
        <div
          style={{
            position: "absolute",
            justifyContent: "center",
            alignItems: "center",
            width: "calc(100% - 15px)"
          }}
          className="bottom-0"
        >
          {googleProfile && (
            <div className="border-t bg-slate-900 py-1">
              <p
                style={{
                  width: "100%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
                className="py-1"
              >
                Logged in as: <br />{googleProfile}
              </p>
              <SideMenuButton
                buttonAction={handleGoogleLogOut}
                text={"Log out of Google"}
              />
            </div>
          )}
          {!googleProfile && <SideMenuButton buttonAction={handleLogout} text={"Log Out"} />}
        </div>



      </div>
      <div className="col-span-10 flex h-screen w-full flex-col p-3">
        
        {/* <div className="grid h-screen grid-flow-row grid-rows-6 gap-4"> */}

        {!googleProfile ? 
        (
          <Box
            height="100vh" 
            display="flex" 
            justifyContent="center" 
            alignItems="center"
          >
            <VStack spacing={4} textAlign="center">
              <Text fontSize="md" fontWeight="bold" lineHeight="1">
                Ahoora is integrated with Google Ads.
              </Text>
              <Text fontSize="md" fontWeight="bold"  lineHeight="1">
                Use your google ads email address to connect.
              </Text>

              <Button size="md" onClick={handleGoogleLogin}>
                Connect with Google Ads
              </Button>
              <img src={VerifiedLogo} alt='bot' className='h-14 bg-transparent my-3 rounded-md border border-transparent' />
            </VStack>
          </Box>
        ) : 
        (
          <section id="chat-section">
            <Box padding="10px">
              { chatGroup &&
                (
                  <Flex justify="center" align="center" position="relative">
                    <Text
                      onClick={toggleDropdown}
                      // align="center"
                      fontSize="xl"
                      fontWeight="bold"
                      marginBottom="10px"
                    >
                      Ahoora Chat
                    </Text>
                    <Box position="absolute" right="0">
                      <ProfileMenu handleLogout={handleLogout} isTestUser={isTestUser} initial={initial} stripePricingModalOnOpen={stripePricingModalOnOpen}/>
                    </Box>
                    

                  </Flex>

                )
              }
              <Box
                  height={chatGroup ? false : "75vh"}
                  display={chatGroup ? false: "flex"}
                  justifyContent={chatGroup ? false: "center"}
                  alignItems={chatGroup ? false: "center"}
              >
                <VStack
                  spacing={chatGroup ? 2 : 8}
                >
                  <ChatConnectorSelector
                    googleProfile={googleProfile}
                    selectedChatConnector={selectedChatConnector}
                    // setSelectedChatConnector={setSelectedChatConnector}
                    selectedGoogleLoginCustomerID={
                      selectedGoogleLoginCustomerID
                    }
                    setSelectedGoogleLoginCustomerID={
                      setSelectedGoogleLoginCustomerID
                    }
                    selectedGoogleCustomerID={selectedGoogleCustomerID}
                    setSelectedGoogleCustomerID={setSelectedGoogleCustomerID}
                    setChatConnectorProfile={setChatConnectorProfile}
                    googleLoginCustomerIds={googleLoginCustomerIds}
                    googleCustomerIds={googleCustomerIds}
                  />

                  { chatGroup ? 
                    ( 
                      <Flex
                        justifyContent="center"
                        direction="column"
                      >
                        {chatConnectorProfile && (
                          <QuestionDropdownMenu message={message} setMessage={setMessage} 
                            fromValue={fromValue} setFromValue={setFromValue} 
                            toValue={toValue} setToValue={setToValue}
                            testUser={isTestUser} questionMode={questionMode}
                            campaignList={campaignList} setSelectedCampaign={setSelectedCampaign}
                            />
                        )}
                        {chatConnectorProfile && questionMode !== 'textBox' && (
                          <HStack spacing={1} alignItems="center">
                            {questionMode === 'compareDate' && (
                              <Popover>
                                <PopoverTrigger>
                                  <Box mt={1}>
                                    {" Period 1 "}
                                    <Button>
                                      {compareFromValue} - {compareToValue}{" "}
                                    </Button>
                                  </Box>
                                </PopoverTrigger>
                                <PopoverContent>
                                  <PopoverBody>
                                    <DateRangePicker
                                      fromValue={compareFromValue}
                                      setFromValue={setCompareFromValue}
                                      toValue={compareToValue}
                                      setToValue={setCompareToValue}
                                    />
                                  </PopoverBody>
                                </PopoverContent>
                              </Popover>
                            )}

                            {(questionMode === 'selectMultipleDropdown' || questionMode ==='selectMultipleDropdownCampaign') && (
                              <DataChartDropdownMenuComponent setSelectedDataCharts={setSelectedDataCharts}/>
                            )}

                            <Popover>
                              <PopoverTrigger>
                                <Box mt={1}>
                                  {questionMode === 'compareDate' ? (" Period 2 ") : (" ")}
                                  <Button>
                                    {fromValue} - {toValue}{" "}
                                  </Button>
                                </Box>
                              </PopoverTrigger>
                              <PopoverContent>
                                {/* <PopoverArrow /> */}
                                {/* <PopoverCloseButton /> */}
                                {/* <PopoverHeader>Confirmation!</PopoverHeader> */}
                                <PopoverBody>
                                  <DateRangePicker
                                    fromValue={fromValue}
                                    setFromValue={setFromValue}
                                    toValue={toValue}
                                    setToValue={setToValue}
                                  />
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </HStack>
                        )}

                        {chatConnectorProfile && questionMode === 'textBox' && (
                          <HStack spacing={1}>
                              <Heading mt={1} as='h5' size='sm'>Ideas</Heading>
                              <Box mt={1}>
                                <Input placeholder="idea 1, idea 2, idea 3 ..." size='sm'
                                value={textboxInputValue}  variant="Filled" colorScheme="cyan"
                                onChange={handletextboxInputChange} />
                              </Box>
                          </HStack>
                        )}
                      </Flex>
                    ) : 
                    (
                      <Button size="md" onClick={handleNewChat}>
                        Ask Ahoora
                      </Button>
                    )
                  }
                </VStack>
              </Box>

              { chatGroup && (
                <section>
                  <Box minHeight="200px" padding="10px" marginBottom="10px">
                    <ChatSummary chats={chatData} chat_id={chatGroup} initial={initial} />
                  </Box>

                  {chatConnectorProfile && (
                    <div
                      style={{ position: "absolute", bottom: "0px", width: "80%" }}
                    >
                      <InputGroup size="md">
                        <input
                          value={message}
                          className="mb-4 w-full rounded-md p-2"
                          style={{
                            pr: "4.5rem",
                            borderradius: "md",
                            flex: "1",
                            marginright: "10px",
                          }}
                          // onChange={(e) => setMessage(e.target.value)}
                          type="text"
                          readOnly={true}
                          placeholder="Ask me a question.."
                        />
                        <InputRightElement width="auto" height="100%">
                          <span className="mb-4 mr-4 flex h-full items-center">
                            {canAsk ? (
                              <Button
                                onClick={handleAskQuestion}
                                h="1.75rem"
                                size="sm"
                              >
                                {" "}
                                Ask{" "}
                              </Button>
                            ) : (
                              <Button
                                h="1.75rem"
                                size="sm"
                                isLoading
                                loadingText="Generating response"
                              >
                                {" "}
                                Ask{" "}
                              </Button>
                            )}
                          </span>
                        </InputRightElement>
                      </InputGroup>
                    </div>
                  )}
                </section>
              )}
            </Box>
          </section>
        )}
      </div>
    </div>
  );
};

export default Dashboard;

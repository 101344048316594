import React from 'react';
import { useNavigate } from "react-router-dom";

import Logo from "../../assets/images/ahoora_metflow.png";

export const NotLoggedIn = () => {
    const navigate = useNavigate();
  
    return (
      <div className="flex h-screen w-full flex-col items-center justify-center bg-zinc-200">
        <img
          src={Logo}
          alt="bot"
          className="my-3 h-14 rounded-md border border-transparent bg-transparent"
        />
        <p className="text-3xl">Oops! Please Login Here</p>
        <button className="my-4 px-6 py-2" onClick={() => navigate("/home")}>
          Login
        </button>
      </div>
    );
  };

export const Loader = () => {
    return <>Loading...</>;
  };
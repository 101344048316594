import React, { useState, useEffect } from 'react';
import { Box, Select, HStack } from '@chakra-ui/react';

const DataChartDropdownMenuComponent = ( {setSelectedDataCharts} ) => {
  // Define the items for the dropdown menus
  const dropdownItems = [
    { header: "Recommended", items: ["Conversions", "Cost", "Cost/conv.", "Impressions", ""]},
    { header: "Performance", items: ["Clicks", "Impressions", "CTR", "Avg. CPC", "Cost", "Interactions", "Interaction rate", ""]},
    { header: "Conversions", items: ["Conversions", "Conv. value", "Cost/conv.", "Conv. rate", "ROAS"]},
  ];

  const [selectedItems, setSelectedItems] = useState(Array(4).fill(""));

  // Handle selection change
  const handleSelectChange = (value, index) => {
    const newSelectedItems = [...selectedItems];
    newSelectedItems[index] = value;
    setSelectedItems(newSelectedItems);
  };

  useEffect(() => {
    if (selectedItems) {
        setSelectedDataCharts(selectedItems);
    }
  }, [selectedItems, setSelectedDataCharts]);

  return (
    <Box mt={1} mx="auto">
        <HStack>
            {selectedItems.map((_, index) => (
            <Select
                placeholder=""
                key={index}
                value={selectedItems[index]}
                onChange={(e) => handleSelectChange(e.target.value, index)}
                borderColor={'#4f46e5'}
                background={'transparent'}
            >
                {dropdownItems.map((group, idx) => (
                <React.Fragment key={idx}>
                    <option disabled style={{ fontWeight: 'bold' }}>
                    {group.header}
                    </option>
                    {group.items.map((item, itemIdx) => (
                    <option key={itemIdx} value={item}>
                        {item}
                    </option>
                    ))}
                </React.Fragment>
                ))}
            </Select>
            ))}
        </HStack>
    </Box>
  );
};

export default DataChartDropdownMenuComponent;

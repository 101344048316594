import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
    return (
        <div className='privacy-policy'>

            <h1 style={{ textAlign: 'center' }}> <strong>Ahoora Privacy Policy</strong></h1> <br />

            <p>Effective: October 1, 2024</p> <br />

            <p>We at Ahoora (together with Metrics Flow Inc., “we”, “our” or “us”) respect your privacy and are
                strongly committed to securing any information we obtain from you. This Privacy Policy
                describes our practices with respect to Personal Information we collect when you use our
                website, applications, and services (collectively, “Services”).
            </p> <br />

            <p>
                The Website and Ahoora’s artificial intelligence platform (the “Ahoora AI Platform”) are
                intended exclusively for marketers in their business capacity. We do not offer products or
                services for use by individuals for their personal, family, or household purposes.
                Accordingly, we treat all personal information we collect in connection with the Website as
                pertaining to individuals in their capacities as representatives of the relevant business and
                not their individual personal capacities.
            </p> <br />

            <section>
                <h2><b>1. Personal information we collect</b></h2>
                <p>
                    We collect personal information relating to you (“Personal Information”) as follows:
                </p>  <br />

                <p>
                    Personal information you provide:  We collect Personal Information if you create an account to
                    use our Services or communicate with us as follows:
                </p>

                <ul className='indented-list'>
                    <li>
                        <em>Account Information: </em> When you create an account with us, we will collect information
                        associated with your account, including your name, contact information, account
                        credentials, payment card information, and transaction history, (collectively, “Account
                        Information”).
                    </li>
                    <li>
                        <em>User Content: </em> When you use our Services, we collect Personal Information
                        that is included in the input, file uploads, or feedback that you provide to our Services
                        (“Content”).
                    </li>
                    <li>
                        <em>Communication Information: </em> If you communicate with us, we collect your name, contact
                        information, and the contents of any messages you send (“Communication Information”).
                    </li>
                    <li>
                        <em>Social Media Information: </em> We have pages on social media sites like Instagram,
                        Facebook, Medium, Twitter, YouTube, and LinkedIn. When you interact with our social
                        media pages, we will collect Personal Information that you elect to provide to us, such as
                        your contact details (collectively, “Social Information”). In addition, the companies that
                        host our social media pages may provide us with aggregate information and analytics
                        about our social media activity.
                    </li>
                    <li>
                        <em>Other Information You Provide: </em> We collect other information that you may provide to us,
                        such as when you participate in our events or surveys or provide us with information to
                        establish your identity (collectively, “Other Information You Provide”).
                    </li>
                </ul> <br />

                <p>
                    Personal information we receive automatically from your use of the Services: When you visit,
                    use, or interact with the Services, we receive the following information about your visit, use, or
                    interactions (“Technical Information”):
                </p> <br />

                <ul className='indented-list'>
                    <li>
                        <em>Log Data: </em>Information that your browser or device automatically sends when you use our
                        Services. Log data includes your Internet Protocol address, browser type and settings,
                        the date and time of your request, and how you interact with our Services.
                    </li>

                    <li>
                        <em>Usage Data: </em> We may automatically collect information about your use of the Services,
                        such as the types of content that you view or engage with, the features you use and the actions you take, as well as your time zone, country, the dates and times of access, user agent and version, type of computer or mobile device, and your computer connection.

                    </li>
                    <li>
                        <em>Device Information: </em>  Includes name of the device, operating system, device identifiers, and browser you are using. Information collected may depend on the type of device you use and its settings.
                    </li>
                    <li>
                        <em>Cookies: </em> We use cookies to operate and administer our Services, and improve your experience. A “cookie” is a piece of information sent to your browser by a website you visit. You can set your browser to accept all cookies, to reject all cookies, or to notify you whenever a cookie is offered so that you can decide each time whether to accept it. However, refusing a cookie may in some cases preclude you from using, or negatively affect the display or function of, a website or certain areas or features of a website.
                    </li>
                    <li>
                        <em>Analytics: </em> We may use a variety of online analytics products that use cookies to help us analyze how users use our Services and enhance your experience when you use the Services.
                    </li>
                </ul> <br />
            </section>

            <section>
                <h2><b>2. How we use personal information</b></h2> <br />

                <p>We may use Personal Information for the following purposes:</p>

                <ul className='tight-list'>
                    <li>To provide, administer, maintain and/or analyze the Services;</li>
                    <li>To improve our Services and conduct research;</li>
                    <li>
                        To communicate with you, including to send you information about our Services and events;
                    </li>
                    <li>To develop new programs and services;</li>
                    <li>
                        To prevent fraud, criminal activity, or misuses of our Services, and to protect the security
                        of our IT systems, architecture, and networks;
                    </li>
                    <li>To carry out business transfers; and</li>
                    <li>
                        To comply with legal obligations and legal processes and to protect our rights, privacy,
                        safety, or property, and/or that of our affiliates, you, or other third parties.
                    </li>
                </ul> <br />

                <p>
                    Aggregated or de-identified information. We may aggregate or de-identify Personal Information so that it may no longer be used to identify you and use such information to analyze the effectiveness of our Services, to improve and add features to our Services, to conduct research and for other similar purposes. In addition, from time to time, we may analyze the general behavior and characteristics of users of our Services and share aggregated information like general user statistics with third parties, publish such aggregated information or make such aggregated information generally available. We may collect aggregated information through the Services, through cookies, and through other means described in this Privacy Policy. We will maintain and use de-identified information in anonymous or de-identified form and we will not attempt to reidentify the information, unless required by law.
                </p> <br />

                <p>
                    As noted above, we may use Content you provide us to improve our Services, for example to train the models that power Ahoora.
                </p> <br />
            </section>

            <section>
                <h2><b>3. Disclosure of personal information</b></h2> <br />
                <p>
                    In certain circumstances we may provide your Personal Information to third parties without further notice to you, unless required by the law:
                </p>

                <ul className='indented-list'>
                    <li>
                        <em>Vendors and Service Providers:</em> To assist us in meeting business operations needs and to perform certain services and functions, we may provide Personal Information to vendors and service providers, including providers of hosting services, customer service vendors, cloud services, email communication software, web analytics services, and other information technology providers, among others. Pursuant to our instructions, these parties will access, process, or store Personal Information only in the course of performing their duties to us.
                    </li>
                    <li>
                        <em>Business Transfers:</em> If we are involved in strategic transactions, reorganization, bankruptcy, receivership, or transition of service to another provider (collectively, a “Transaction”), your Personal Information and other information may be disclosed in the diligence process with counterparties and others assisting with the Transaction and transferred to a successor or affiliate as part of that Transaction along with other assets.
                    </li>
                    <li>
                        <em>Legal Requirements:</em> We may share your Personal Information, including information about your interaction with our Services, with government authorities, industry peers, or other third parties (i) if required to do so by law or in the good faith belief that such action is necessary to comply with a legal obligation, (ii) to protect and defend our rights or property, (iii) if we determine, in our sole discretion, that there is a violation of our terms, policies, or the law; (iv) to detect or prevent fraud or other illegal activity; (v) to protect the safety, security, and integrity of our products, employees, or users, or the public, or (vi) to protect against legal liability.
                    </li>
                    <li>
                        <em>Affiliates:</em> We may disclose Personal Information to our affiliates, meaning an entity that controls, is controlled by, or is under common control with Ahoora. Our affiliates may use the Personal Information we share in a manner consistent with this Privacy Policy.
                    </li>
                    <li>
                        <em>Business Account Administrators:</em> When you join an AhooraAI account, the administrators of that account may access and control your Ahoora account. In addition, if you create an account using an email address belonging to your employer or another organization, we may share the fact that you have an Ahoora account and certain account information, such as your email address, with your employer or organization to, for example, enable you to be added to their business account.
                    </li>
                    <li>
                        <em>Other Users and Third Parties You Share Information With:</em> Certain features allow you to display or share information with other users or third parties. For example, you may share Ahoora conversations with other users via shared links or send information to third-party applications via custom actions for Ahoora. Be sure you trust any user or third party with whom you share information.
                    </li>
                </ul> <br />
            </section>

            <section>
                <h2><b>4. Your rights</b></h2> <br />

                <p>
                    Depending on location, individuals may have certain statutory rights in relation to their Personal Information. For example, you may have the right to:
                </p>

                <ul className='tight-list'>
                    <li>
                        Access your Personal Information and information relating to how it is processed.
                    </li>
                    <li>
                        Delete your Personal Information from our records.
                    </li>
                    <li>
                        Rectify or update your Personal Information.
                    </li>
                    <li>
                        Transfer your Personal Information to a third party (right to data portability).
                    </li>
                    <li>
                        Restrict how we process your Personal Information.
                    </li>
                    <li>
                        Withdraw your consent—where we rely on consent as the legal basis for processing at any time.
                    </li>
                    <li>
                        Object to how we process your Personal Information.
                    </li>
                    <li>
                        Lodge a complaint with your local data protection authority.
                    </li>
                </ul> <br />

                <p>
                    You can exercise some of these rights through your Ahoora account. If you are unable to exercise your rights through your account, please submit your request through privacy@ahoora.ai
                </p> <br />

                <p>
                    A note about accuracy: Services like AhooraAI generate responses by reading a user’s request and, in response, predicting the words most likely to appear next. In some cases, the words most likely to appear next may not be the most factually accurate. For this reason, you should not rely on the factual accuracy of output from our models. If you notice that Ahoora output contains factually inaccurate information and you would like us to correct the inaccuracy, you may submit a correction request through privacy@ahoora.ai
                </p> <br />
            </section>

            <section>
                <h2><b>5. Additional disclosures</b></h2> <br />

                <p>
                    The following table provides additional information about the categories of Personal
                    Information we collect and how we disclose that information. You can read more about the
                    Personal Information we collect in “Personal information we collect” above, how we use
                    Personal Information in “How we use personal information” above, and how we retain Personal
                    Information in “Security and Retention” below.
                </p>

                <table className="info-table">
                    <thead>
                        <tr>
                            <th>Category of Personal Information</th>
                            <th>Disclosure of Personal Information</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                Identifiers, such as your name, contact details, IP address, and other device
                                identifiers
                            </td>
                            <td>
                                We may disclose this information to our affiliates, vendors and service providers to
                                process in accordance with our instructions; to law enforcement and other third
                                parties for the legal reasons described above; to parties involved in Transactions; to
                                corporate administrators of enterprise or team accounts; and to other users and third
                                parties you choose to share it with.
                            </td>
                        </tr>
                        <tr>
                            <td>Commercial Information, such as your transaction history</td>
                            <td>
                                We may disclose this information to our affiliates, vendors and service providers to
                                process in accordance with our instructions; to law enforcement and other third
                                parties for the legal reasons described above; to parties involved in Transactions;
                                and to corporate administrators of enterprise or team accounts.
                            </td>
                        </tr>
                        <tr>
                            <td>Network Activity Information, such as Content and how you interact with our Services</td>
                            <td>
                                We may disclose this information to our affiliates, vendors and service providers to
                                process in accordance with our instructions; to law enforcement and other third
                                parties for the legal reasons described above; to parties involved in Transactions;
                                and to other users and third parties you choose to share it with.
                            </td>
                        </tr>
                        <tr>
                            <td>Geolocation Data</td>
                            <td>
                                We may disclose this information to our affiliates, vendors and service providers to
                                process in accordance with our instructions; to law enforcement and other third
                                parties for the legal reasons described above; and to parties involved in Transactions.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Your account login credentials and payment card information (Sensitive Personal
                                Information)
                            </td>
                            <td>
                                We disclose this information to our affiliates, vendors and service providers, law
                                enforcement, and parties involved in Transactions.
                            </td>
                        </tr>
                    </tbody>
                </table>

                <p>
                    To the extent provided for by local law and subject to applicable exceptions, individuals may have the following privacy rights in relation to their Personal Information:
                </p>

                <ul className='tight-list'>
                    <li>
                        The right to know information about our processing of your Personal Information, including the specific pieces of Personal Information that we have collected from you;
                    </li>
                    <li>
                        The right to request deletion of your Personal Information;
                    </li>
                    <li>
                        The right to correct your Personal Information; and
                    </li>
                    <li>
                        The right to be free from discrimination relating to the exercise of any of your privacy rights.
                    </li>
                </ul> <br />

                <p>
                    We don’t “sell” Personal Information or “share” Personal Information for cross-contextual behavioral advertising (as those terms are defined under applicable local law). We also don’t process sensitive Personal Information for the purposes of inferring characteristics about a consumer.
                </p> <br />

                <p>
                    <em>Verification. </em>
                    In order to protect your Personal Information from unauthorized access, change, or deletion, we may require you to verify your credentials before you can submit a request to know, correct, or delete Personal Information. If you do not have an account with us, or if we suspect fraudulent or malicious activity, we may ask you to provide additional Personal Information and proof of residency for verification. If we cannot verify your identity, we will not be able to honor your request.
                </p> <br />

                <p>
                    <em>Authorized agents. </em>
                    You may also submit a rights request through an authorized agent. If you do so, the agent must present signed written permission to act on your behalf and you may also be required to independently verify your identity and submit proof of your residency with us. Authorized agent requests can be submitted to privacy@ahoora.ai
                </p> <br />

                <p>
                    <em>Appeals. </em>
                    Depending on where you live, you may have the right to appeal a decision we make relating to requests to exercise your rights under applicable local law. To appeal a decision, please send your request to privacy@ahoora.ai
                </p> <br />


            </section>

            <section>
                <h2><b>6. Children</b></h2> <br />
                <p>
                    Our Service is not directed to children under the age of 13. AhooaAI does not knowingly collect Personal Information from children under the age of 13. If you have reason to believe that a child under the age of 13 has provided Personal Information to Ahoora through the Service, please email us at privacy@ahoora.ai. We will investigate any notification and if appropriate, delete the Personal Information from our systems. If you are 13 or older, but under 18, you must have permission from your parent or guardian to use our Services.
                </p> <br />
            </section>

            <section>
                <h2><b>7. Links to other websites</b></h2> <br />
                <p>
                    The Service may contain links to other websites not operated or controlled by Ahoora, including social media services (“Third Party Sites”). The information that you share with Third Party Sites will be governed by the specific privacy policies and terms of service of the Third Party Sites and not by this Privacy Policy. By providing these links we do not imply that we endorse or have reviewed these sites. Please contact the Third Party Sites directly for information on their privacy practices and policies.
                </p> <br />
            </section>

            <section>
                <h2><b>8. Security and retention</b></h2> <br />
                <p>
                    We implement commercially reasonable technical, administrative, and organizational measures to protect Personal Information both online and offline from loss, misuse, and unauthorized access, disclosure, alteration, or destruction. However, no Internet or email transmission is ever fully secure or error free. In particular, email sent to or from us may not be secure. Therefore, you should take special care in deciding what information you send to us via the Service or email. In addition, we are not responsible for circumvention of any privacy settings or security measures contained on the Service, or third-party websites.
                </p> <br />
                <p>
                    We’ll retain your Personal Information for only as long as we need in order to provide our Service to you, or for other legitimate business purposes such as resolving disputes, safety and security reasons, or complying with our legal obligations. How long we retain Personal Information will depend on a number of factors, such as the amount, nature, and sensitivity of the information, the potential risk of harm from unauthorized use or disclosure, our purpose for processing the information, and any legal requirements.
                </p> <br />
            </section>

            <section>
                <h2><b>9. International users</b></h2> <br />
                <p>
                    By using our Service, you understand and acknowledge that your Personal Information will be processed and stored in our facilities and servers in Canada and the United States and may be disclosed to our service providers and affiliates in other jurisdictions.
                </p> <br />

                <p>
                    <em>Legal basis for processing. </em>
                    Our legal bases for processing your Personal Information include:
                </p>

                <ul className='tight-list'>
                    <li>
                        <em>Performance of a contract with you when we provide and maintain our Services. </em>
                        When we process Account Information, Content, and Technical Information solely to provide our Services to you, this information is necessary to be able to provide our Services. If you do not provide this information, we may not be able to provide our Services to you.
                        Our legitimate interests in protecting our Services from abuse, fraud, or security risks, or in developing, improving, or promoting our Services, including when we train our models. This may include the processing of Account Information, Content, Social Information, and Technical Information.
                    </li>
                </ul>

                <p>
                    <em>Data transfers. </em>
                    Where required, we will use appropriate safeguards for transferring Personal Information outside of certain countries. We will only transfer Personal Information pursuant to a legally valid transfer mechanism.
                </p>

                <p>
                    <em>Data protection officer. </em>
                    You can contact our data protection officer at privacy@ahoora.ai in matters related to Personal Information processing.
                </p> <br />
            </section>


            <section>
                <h1><b>10. Changes to the privacy policy</b></h1> <br />
                <p>
                    We may update this Privacy Policy from time to time. When we do, we will post an updated version on this page, unless another type of notice is required by applicable law.
                </p> <br />
            </section>

            <section>
                <h2><b>11. How to contact us</b></h2> <br />
                <p>
                    Please privacy@ahoora.ai if you have any questions or concerns not already addressed in this Privacy Policy.
                </p> <br />
            </section>
        </div >
    );
};

export default PrivacyPolicy;

import React, { useEffect, useRef } from "react";
// import { Box, Flex, Text } from '@chakra-ui/react';

import "./chatsummary.css";
import botImage from "../../assets/images/aibot.png";
import JSONTable from "../JSONTable";
import ChatBarChart from "../Charts/BarChart";

const calloutResponseText1 = "calloutResponse";
const calloutResponseText2 = "callouts";
const croasResponseText = "croas response";

function ChatSummary({ chats, chat_id, initial }) {
  const messageBodyRef = useRef(null);
  // const [typingAnimation, setTypingAnimation] = useState(false);

  useEffect(() => {
    if (messageBodyRef.current) {
      const { scrollHeight, clientHeight } = messageBodyRef.current;

      // Only scroll to the bottom if new chats have been added
      if (scrollHeight > clientHeight) {
        messageBodyRef.current.scrollTop = scrollHeight - clientHeight;
      }
    }
  }, [chats]);

  function getDataCampaign(str) {
    if (str.charAt(0) === '[') {
      return false;
    }

    const index = str.indexOf('[');
    if (index === - 1) {
      return false;
    }

    return index;
  }


  function formatBarChartResponse(data) {
    let tmp =  getDataCampaign(data);
    if (tmp) {
      var campaign = data.slice(0, tmp)
      data = data.slice(tmp)
    }
    let parsedData = JSON.parse(data);

    const parsedDataKeys = Array.isArray(parsedData) && parsedData.length > 0 && typeof parsedData[0] === 'object' && parsedData[0] !== null 
      ? Object.keys(parsedData[0]).filter(key => key !== 'Date' && key !== 'Day') 
      : [];

    const stats = {};
    parsedDataKeys.forEach(key => {
      stats[key] = {
        total: 0,
        count: 0,
        highestDay: null,
        highestValue: -Infinity,
        lowestDay: null,
        lowestValue: Infinity,
      }
    });

    parsedData.forEach(entry => {
      Object.keys(entry).forEach(key => {
        if (key !== "Date" && parsedDataKeys.includes(key)) {
          const value = entry[key];
          const day = entry["Date"];

          const stat = stats[key];
          stat.total += value;
          stat.count += 1;
    
          if (value < stat.lowestValue) {
            stat.lowestValue = value;
            stat.lowestDay = day;
          }
          if (value > stat.highestValue) {
            stat.highestValue = value;
            stat.highestDay = day;
          }
        }
      });
    });

    const outputElements = [];
    
    const symbolMapping = {
      "Conversions": ['', 'conversions', 'conversion'],
      "Cost": ['$', 'spend', 'spend'],
      "Cost/conv.": ['$', 'cost/conv.', 'cost/conv.'],
      "Impressions": ['', 'impressions', 'impression'],
      "Clicks": ['', 'clicks', 'click'],
      "CTR": ['%', 'CTR', 'CTR'],
      "Avg. CPC": ['$', 'avg. CPC', 'avg. CPC'],
      "Interactions": ['', 'interactions', 'interaction'],
      "Interaction rate": ['%', 'interaction rate', 'interaction rate'],
      "Conv. value": ['', 'conv. value', 'conv. value'],
      "Conv. rate": ['%', 'conv. rate', 'conv. rate'],
      "ROAS": ['%', 'ROAS', 'ROAS'],
    };

    parsedDataKeys.forEach((key, index) => {
      var outputText = ''
      const stat = stats[key];

      if (!symbolMapping[key]) {
        return;
      }

      var suffix = symbolMapping[key][0] || '';
      const keyValue1 = symbolMapping[key][1] || key.toLowerCase();
      const keyValue2 = symbolMapping[key][2] || key.toLowerCase();

      if (stat.count > 0) {
        if (index === 0) {
          if (tmp) {
            outputText += `For ${campaign}: `;
          } else {
            outputText += `Across all campaigns: `;
          }
          outputElements.push(<p key={`header-${index}`}><b>{outputText}</b></p>)
          outputText = ''
        }

        if (suffix === '$' || suffix ==='%') {
          outputText += `The highest ${keyValue2} was on ${stat.highestDay} with a value of ${suffix === '$' ? ` ${suffix}${stat.highestValue.toLocaleString()}` : ` ${stat.highestValue.toLocaleString()}${suffix}`}. The lowest ${keyValue2} was on ${stat.lowestDay} with a value of ${suffix === '$' ? ` ${suffix}${stat.lowestValue.toLocaleString()}` : ` ${stat.lowestValue.toLocaleString()}${suffix}`}.`;

        } else {
          outputText += `The total ${keyValue1} was ${stat.total.toFixed(2).toLocaleString()}${suffix}. The highest ${keyValue2} was on ${stat.highestDay} with a value of ${stat.highestValue.toLocaleString()}${suffix}. The lowest ${keyValue2} was on ${stat.lowestDay} with a value of ${stat.lowestValue.toLocaleString()}${suffix}.`;
        }
        outputElements.push(<p key={`content-${index}`}>{outputText}</p>)
      }
    });

    return (
      <div>
        <ChatBarChart data={parsedData}/>
        <br />
        <div>{outputElements}</div>
      </div>
      
    );
  }

  function formatRelativeKeywordsResponse(inputString) {
    const textBeforeJSON = inputString.split('  [')[0];

    const arrayMatches = inputString.match(/\[.*?\]/g);    
    let jsonObject1 = null;
    let jsonObject2 = null;
    
    if (arrayMatches && arrayMatches.length > 0) {
      jsonObject1 = JSON.parse(arrayMatches[0]); // First array
    }
    if (arrayMatches && arrayMatches.length > 1) {
      jsonObject2 = JSON.parse(arrayMatches[1]); // Second array (if present)
    }

    return (
      <div>
        <p className="mb-2">{textBeforeJSON}</p>
        <p> Your top performing keywords are listed below:</p>
        <JSONTable data={jsonObject1} text={'Non-Branded'}/>
        <JSONTable data={jsonObject2} text={'Branded'}/>
      </div>
    );
  }

  function formatCROASResponse(inputString) {
    let jsonString = inputString.substring(inputString.indexOf("{"), inputString.lastIndexOf("}") + 1);
    let jsonObject = JSON.parse(jsonString);
    
    const keyValueElements = [];
    for (var key in jsonObject) {
      keyValueElements.push(
        <div>
          <p>{jsonObject[key]}</p>      
        </div>
      );
    }

    return (
      <div> 
        {keyValueElements}
      </div>
    );
  }

  function formatCalloutResponse(inputString) {
    const regex = /(.+?)(?={)/;

    const match = regex.exec(inputString);
    let textBeforeJSON = inputString;
    let jsonObject = {};

    if (match) {
      
      let jsonString = inputString.substring(match[1].length);
      jsonString = jsonString.replace(/\\"/g, "\"");

      // Change to last instance??
      if (jsonString.charAt(jsonString.length - 1) !== '}') {
        jsonString = jsonString.slice(0, -1);
      }

      jsonObject = JSON.parse(jsonString);
      textBeforeJSON = match[1].trim();
    
      if (textBeforeJSON.charAt(textBeforeJSON.length - 1) === '"') {
        textBeforeJSON = textBeforeJSON.slice(0, -1);
      }
    }

    const keyValueElements = [];
    const divStyleKey = {
      display: 'inline-block',
      marginTop: '5px',
    };

    const liStyleValue = {
      padding: '0em',
      marginLeft: '20px',
      listStyle: 'decimal',
      listStylePosition: 'inside',
    }
    // const olStyleValue = {
    //   display: 'list-item',
    // }
  
    function outputObject(jsonObject, key, showTop=true) {
      const valueArray = jsonObject[key];

      keyValueElements.push(
        <div key={`${key}`}>
          <div style={divStyleKey}>
            <br />
            <b>{key}:</b>
          </div>
        </div>
      );

      if (!showTop || valueArray.length <= 1) {
        keyValueElements.push(
          <div>
              <p>{valueArray}</p>
          </div>
        );
        return;
      }

      let firstItem = true;
      let listItems = [];
      let count = 0;
  

      for (const element of valueArray) {
        if (firstItem) {
            firstItem = false;
            keyValueElements.push(
              <div key={`${key}-${element}`}>
                  <p>{element}</p>
                  <br />
                  <p>The top campaigns are:</p>
              </div>
            );
        } else {
          listItems.push(
            // <div key={`${key}-${element}`} style={divStyleValue}>
              <li key={count} style={liStyleValue}>{element}</li>
            // </div>
          );
          count += 1;
        }
      }

      keyValueElements.push(
          <ol>
            {listItems}
          </ol>
      );
    }

    if (Object.prototype.hasOwnProperty.call(jsonObject, 'Overview')) {
      outputObject(jsonObject, 'Overview', false);
      delete jsonObject['Overview'];
    }

    for (const key in jsonObject) {
      if (Object.prototype.hasOwnProperty.call(jsonObject, key)) {
        outputObject(jsonObject, key, true)
      }
    }

    return (
      <div>
        <p className="mb-2">{textBeforeJSON}</p>
        {keyValueElements}
      </div>
    );
  }

  function extractTextAndJSON(inputString, message_type) {
    if (!message_type) {
      message_type = 'string'
    } else if (message_type === 'barChart') {
      return formatBarChartResponse(inputString);
    } else if (message_type === 'relativeKeywords') {
      return formatRelativeKeywordsResponse(inputString);
    }

    const openingBraceIndex = inputString.indexOf("{");
    const closingBraceIndex = inputString.lastIndexOf("}");

    let textBeforeJSON = "";
    let jsonSubstring = inputString;

    // Check if the inputString is an array of objects within a string
    if (
      inputString.includes("[{") &&
      inputString.includes("}]") &&
      openingBraceIndex !== -1 &&
      closingBraceIndex !== -1
    ) {
      textBeforeJSON = inputString
        .substring(0, inputString.indexOf("[{"))
        .trim();
      jsonSubstring = inputString.substring(
        inputString.indexOf("[{"),
        inputString.lastIndexOf("}]") + 2
      );
    }

    try {
      if (inputString.includes(calloutResponseText1) || inputString.includes(calloutResponseText2)) {
        return formatCalloutResponse(inputString);
      } else if (inputString.includes(croasResponseText)){
        return formatCROASResponse(inputString);
      }

      jsonSubstring = jsonSubstring.replace(/NaN/g, '""');
      const jsonObject = JSON.parse(jsonSubstring);

      if (textBeforeJSON && Object.keys(jsonObject).length > 0) {
        // Both text and JSON, return both in a div
        return (
          <div>
            <JSONTable data={jsonObject} text={textBeforeJSON}/>
          </div>
        );
      } else if (textBeforeJSON) {
        // Only text, return it
        return <div>{textBeforeJSON}</div>;
      } else if (Object.keys(jsonObject).length > 0) {
        // Only JSON, return JSONTable
        return <JSONTable data={jsonObject} />;
      }
    } catch (error) {
      // console.log(error)
      // JSON parsing error, return the original string
      return <div>{inputString}</div>;
    }

    // No JSON found, return the original string
    return <div>{inputString}</div>;
  }

  // useEffect(() => {
  //     if (typingAnimation) {
  //       const timer = setTimeout(() => {
  //         setMessages((prevMessages) => [
  //           ...prevMessages,
  //           { content: "Typing...", alignRight: false },
  //         ]);
  //         clearTimeout(timer);
  //         setTypingAnimation(false);
  //       }, 1500);
  //     }
  //   }, [typingAnimation]);

  const filteredChats = chats.filter((chat) => chat.id === chat_id);

  return filteredChats.map((chat, index) => {
    return (
      <div
        style={{
          maxHeight: "calc(100vh - 270px)",
          maxWidth: "80vw",
        }}
        className="wrapper overflow-y-auto overflow-x-auto bg-slate-600 scroll-container"
        key={index}
        ref={messageBodyRef}
      >
        {chat.chats.map((ele, index) => (
          <div
            className={`my-2 flex items-center rounded-md p-3 text-white ${
              ele.is_from_user ? "bg-slate-800" : "bg-slate-600"
            }`}
            key={index}
          >
            {ele.is_from_user ? (
              <p className="mr-2 rounded-md bg-indigo-600 px-3 py-2 text-white">
                {initial}
              </p>
            ) : (
              <img
                src={botImage}
                className="mr-2 h-10 rounded-md bg-white"
                alt="bot"
              />
            )}
            {extractTextAndJSON(ele.message, ele.message_type)}
          </div>
        ))}
      </div>
      // <Box flex="1" border="1px solid gray" minHeight="200px" padding="10px" marginBottom="10px" key={index} ref={messageBodyRef}>
      //     {/* <div className='wrapper max-h-[380px] overflow-y-auto' key={index} ref={messageBodyRef}> */}
      //     {chat.chats.map((ele, index) =>
      //         <Flex key={index} alignItems="center" marginBottom="8px">
      //             {ele.is_from_user ? <p className='bg-indigo-600 text-white rounded-md px-3 py-2 mr-2'>{initial.toUpperCase()}</p> : <img src={botImage} className='h-10 rounded-md mr-2 bg-white' alt='bot' />}
      //             <Text
      //                 textAlign={ele.is_from_user ? "right" : "left"}
      //                 color={ele.is_from_user ? "white" : "black"}
      //                 backgroundColor={ele.is_from_user ? "purple.500" : "gray.100"}
      //                 borderRadius="md"
      //                 padding="8px"
      //                 marginBottom="4px"
      //             >

      //             {/* <div className={`flex items-center text-white rounded-md my-2 p-3 ${ele.is_from_user ? 'bg-slate-800' : 'bg-slate-600'}`} key={index}> */}

      
      //             {ele.message}
      //         </Text>
      //     </Flex>)}
      // </Box>
    );
  });
}

export default ChatSummary;

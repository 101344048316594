import React from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

const symbolMapping = {
  "Cost": '$',
  "Cost/conv.": '$',
  "CTR": '%',
  "Avg. CPC": '$',
  "Interaction rate": '%',
  "Conv. rate": '%',
  "ROAS": '%',
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{ backgroundColor: '#333', color: '#fff', padding: '10px', borderRadius: '5px' }}>
        <p>Date: {label}</p>
        {payload.map((entry, index) => {
          const suffix = symbolMapping[entry.name] || '';
          
          return (
            <p key={`item-${index}`} style={{ color: entry.color || '#fff' }}>
              {entry.name}: 
              {suffix === '$' ? ` ${suffix}${entry.value}` : ` ${entry.value}${suffix}`}
            </p>
          );
        })}
      </div>
    );
  }

  return null;
};

const CustomXAxisTickFormatter = (value, index, data) => {
  if (index === 0 || index === data.length - 1) {
    return value; // Show label
  }
  return ""; // Hide label
};

// const lineDefinitions = [
//   { key: 'Conversions', color: '#82ca9d', yAxisId: 'Conversions' },
//   { key: 'Cost', color: '#8884d8', yAxisId: 'Cost' },
//   { key: 'Cost/conv.', color: '#ffc658', yAxisId: 'Cost/conv.' },
//   { key: 'Impressions', color: '#ff7300', yAxisId: 'Impressions' },
//   { key: 'Clicks', color: '#ff7300', yAxisId: 'Clicks' },
//   { key: 'CTR', color: '#ff7300', yAxisId: 'CTR' },
//   { key: 'Interactions', color: '#ff7300', yAxisId: 'Interactions' },
//   { key: 'Interaction rate', color: '#ff7300', yAxisId: 'Interaction rate' },
//   { key: 'Conv. value', color: '#ff7300', yAxisId: 'Conv. value' },
//   { key: 'Conv. rate', color: '#ff7300', yAxisId: 'Conv. rate' },
//   { key: 'ROAS', color: '#ff7300', yAxisId: 'ROAS' },
// ];

const ChatBarChart = ({ data }) => {
  const dataKeys = Array.isArray(data) && data.length > 0 && typeof data[0] === 'object' && data[0] !== null ? Object.keys(data[0]).filter(key => key !== 'Date') : [];

  const lineDefinitions = dataKeys.map((key, index) => ({
    key: key,
    color: ['#8884d8', '#82ca9d', '#ffc658', '#ff7300'][index % 4],
    yAxisId: key,
    orientation:['left', 'right'][index % 2],
  }));

  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis dataKey="Date" tick={{ fill: 'white' }} tickFormatter={(value, index) => CustomXAxisTickFormatter(value, index, data)}/>

        {lineDefinitions.map((line, index) => (
          <YAxis
            key={index}
            yAxisId={line.yAxisId}
            orientation={line.orientation}
            tick={false}
            axisLine={false}
          />
        ))}

        <Tooltip content={<CustomTooltip />}/>

        {lineDefinitions.map((line, index) => (
          <Line
            key={index}
            yAxisId={line.yAxisId}
            type="monotone"
            dataKey={line.key}
            stroke={line.color}
            activeDot={{ r: 5 }}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  )};

export default ChatBarChart;
